const initialState = {
  postNewPharmacyLoading: false,
  postNewPharmacyError: null
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case "LANDINGPAGE_POST_PHARMACY_LOADING":
      return {
        ...state,
        postNewPharmacyLoading: true
      };

    case "LANDINGPAGE_POST_PHARMACY_SUCCESS":
      return {
        ...state,
        postNewPharmacyLoading: false,
        postNewPharmacyError: null
      };

    case "LANDINGPAGE_POST_PHARMACY_ERROR":
      return {
        ...state,
        postNewPharmacyLoading: false,
        postNewPharmacyError: action.payload
      };

    default:
      return { ...state };
  }
};

export default order;
