import axios from "axios";
import * as api from "../../../constants/apiBase";
import md5 from "md5"

const token = localStorage.getItem("token");

const saveSession = response => {
  localStorage.setItem("token", response.accessToken);
  localStorage.setItem("user", response.login);
  localStorage.setItem("userId", response.id);
  localStorage.setItem("farmaciaId", response.farmaciaId);
  localStorage.setItem("perfilId", response.perfilId);
  localStorage.setItem("admin", Boolean(response.admin));
  localStorage.setItem('nome', response.nome);
  localStorage.setItem("canCheckout", Boolean(response.podeConfirmar));
  
  setAuthToken(response.accessToken);
};

const setAuthToken = token => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

if (token) {
  setAuthToken(token);
}

export const verifyLogin = () => async (dispatch) => {
  if(token){
    const pharmacyId =  localStorage.getItem("farmaciaId");
    const login = localStorage.getItem("user");
    if(login !== null && pharmacyId !== null) {
      dispatch({type:"SUCCESS_LOGIN", payload: login });
    }
  }
}

export const loginAction = (user, pass) => async dispatch => {
  dispatch({ type: "SET_LOADING_LOGIN", loading:true});
  axios.post(`${api.SIGNIN_USERS}`, {Login: user, Senha: md5(pass)}).then(response => {
      const {emailConfirmado} = response.data;
      saveSession(response.data);
      if(emailConfirmado){
        dispatch({type:"SUCCESS_LOGIN", payload: response.data.nome || response.data.login });
      }
    })
    .catch(() => {
      dispatch({ type: "ERROR_LOGIN" });
    });
};

export const logoutAction = () => async dispatch => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("userId");
  localStorage.removeItem("farmaciaId");
  localStorage.removeItem("perfilId");
  localStorage.removeItem("admin");
  localStorage.removeItem("canCheckout");
  localStorage.removeItem("viewMessageModal");

  setAuthToken(null);

  dispatch({ type: "SUCCESS_LOGOUT" });
};

export const registerAction = payload => async dispatch => {
  dispatch({ type: "SET_LOADING_REGISTER", loading: true });

  //
};

export const clearErrorLogin = () => async dispatch => {
  dispatch({ type: "CLEAR_ERROR_LOGIN" });
};
