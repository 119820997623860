import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ai } from "./TelemetryService";
import { IS_PROD } from "../../constants/apiBase";

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking
 * that requires this to be a Class Component rather than a Functional Component
 */
const TelemetryProvider = ({ instrumentationKey, children, history }) => {
  const [inicializado, setInicioalizado] = useState(false);

  useEffect(() => {
    if (!IS_PROD) return;

    const AppInsightsInstrumentationKey = instrumentationKey; // PUT YOUR KEY HERE

    if (!inicializado && Boolean(AppInsightsInstrumentationKey)) {
      ai.initialize(AppInsightsInstrumentationKey, history);
      setInicioalizado(true);
    }
  }, []);

  return <>{children}</>;
};

export default withRouter(withAITracking(null, TelemetryProvider));
