import { SNACK_TIME_OUT } from "../constants/apiBase";
import store from "../containers/app/store";

class StorageDefault {
  getStorage = () => {
    const storage = {
      token: localStorage.getItem('token'),
      user: localStorage.getItem('user'),
      userId: localStorage.getItem('userId'),
      farmaciaId: localStorage.getItem('farmaciaId'),
      perfilId: localStorage.getItem('perfilId'),
      perfilNome: localStorage.getItem('nome'),
      admin: localStorage.getItem('admin'),
      canCheckout: localStorage.getItem('canCheckout') === 'true',
      menuState: localStorage.getItem('menuState'),
    };
    return storage;
  }

  getCurrentPharmId = () => {
    let farmaciaId = localStorage.getItem('farmaciaId');

    if (farmaciaId === null) {
      store.dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          open: true,
          message: 'Acesso não autorizado, efetue o login novamente',
        },
      });

      setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("userId");
        localStorage.removeItem("farmaciaId");
        localStorage.removeItem("perfilId");
        localStorage.removeItem("admin");
        localStorage.removeItem("canCheckout");

        window.location = "/";
      }, SNACK_TIME_OUT);

      return null;
    }

    return farmaciaId;
  }
}

export default StorageDefault;
