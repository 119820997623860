const initialState = {
  list: [],
  openImgSelector: false,
  embedFormError: false,
  embedFormLoading: false,
  recipe: {
    id: null,
    criado: null,
    titulo: "",
    descricao: "",
    urlImagem: null,
    imagemId: null,
    farmaciaId: null,
    prescritorId: null,
    clienteId: null,
    pedidoId: null,
  },
};

const recipes = (state = initialState, action) => {
  switch (action.type) {
    case "GET_RECIPE_LIST_SUCCESS":
      return {
        ...state,
        list: action.payload,
        updateRecipeList: false
      };

    case "CLEAR_RECIPE_LIST":
      return {
        ...initialState
      };

    case "GET_RECIPE_DETAILS_SUCCESS":
      return {
        ...state,
        recipe: action.payload
      };

    case "CLEAR_RECIPE_DETAILS":
      return {
        ...state,
        recipe: initialState.recipe
      };

    case "REMOVE_RECIPE_SUCCESS":
      return {
        ...state,
        recipe: initialState.recipe
      };

    case "PUT_RECIPE_SUCCESS":
      return { 
        ...state,
        updateRecipeList: true
      };

    case "POST_RECIPE_SUCCESS":
      return { 
        ...state,
        updateRecipeList: true
      };

    case "POST_IMAGE_RECIPE_SUCCESS":
      return { 
        ...state,
        updateRecipeList: true
       };

    case "EMBEDFORM_POST_LOADING":
      return { 
        ...state,
        embedFormLoading: true,
        embedFormError: false,
      };

    case "EMBEDFORM_POST_SUCCESS":
      return { 
        ...state,
        embedFormLoading: false,
        embedFormError: false,
      };

    case "EMBEDFORM_POST_ERROR":
      return { 
        ...state,
        embedFormLoading: false,
        embedFormError: true,
      };

    case "RECIPE_IMAGE_SELECTOR_CHANGE":
      return {
        ...state,
        openImgSelector: !state.openImgSelector
      }
      
    default:
      return { ...state };
  }
};

export default recipes;
