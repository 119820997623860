import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Loadable from 'react-loadable';

// User Imports
import { APPLY_SANDBOX_VIEW } from '../../constants/apiBase';
import StorageDefault from '../../utils/StorageDefault';
import LoadingImage from '../../components/LoadingImage';
import { verifyLogin } from '../logon/redux/logonActions';
import MessageModal from '../../components/MessageModal';
import withTracker from '../../utils/withTracker';

// COMPONENTS
const Skeleton =             Loadable({ loader: () => import('../skeleton'), loading: LoadingImage });
const Dash =                 Loadable({ loader: () => import('../dash'), loading: LoadingImage });
const Attendance =           Loadable({ loader: () => import('../attendance'), loading: LoadingImage });
const AttendanceDetails =    Loadable({ loader: () => import('../attendance/details'), loading: LoadingImage });
const Operations =           Loadable({ loader: () => import('../operations'), loading: LoadingImage });
const Expedition =           Loadable({ loader: () => import('../expedition'), loading: LoadingImage });
const ExpeditionDetails =    Loadable({ loader: () => import('../expedition/details'), loading: LoadingImage });
const QuotationDetails =     Loadable({ loader: () => import('../quotation/details'), loading: LoadingImage });
const RenegotiationDetails = Loadable({ loader: () => import('../renegotiation/details'), loading: LoadingImage });
const OrderDetails =         Loadable({ loader: () => import('../order/details'), loading: LoadingImage });
const ProductionDetails =    Loadable({ loader: () => import('../production/details'), loading: LoadingImage });
const Client =               Loadable({ loader: () => import('../client'), loading: LoadingImage });
const ClientDetails =        Loadable({ loader: () => import('../client/details'), loading: LoadingImage });
const SignupPharmacy =       Loadable({ loader: () => import('../signupPharmacy'), loading: LoadingImage });
const NotFound =             Loadable({ loader: () => import('../notFound'), loading: LoadingImage });
const NotForMobile =         Loadable({ loader: () => import('../NotForMobile'), loading: LoadingImage });
const WindowedOrderDetaisl = Loadable({ loader: () => import('../windowedOrderDetaisl'), loading: LoadingImage });
const PopIpImage =           Loadable({ loader: () => import('../popUpImage'), loading: LoadingImage });
const SandBox =              Loadable({ loader: () => import('../sandBox'), loading: LoadingImage });
const Help =                 Loadable({ loader: () => import('../help'), loading: LoadingImage });
const Products =             Loadable({ loader: () => import('../product'), loading: LoadingImage });

// ADMIN ROUTE
const Config =            Loadable({ loader: () => import('../config'), loading: LoadingImage });
const SignatureConfig =   Loadable({ loader: () => import('../config/components/Signature'), loading: LoadingImage });
const MoreConfig =        Loadable({ loader: () => import('../config/components/More'), loading: LoadingImage });
const Integration =       Loadable({ loader: () => import('../config/components/Integragion'), loading: LoadingImage });
const PaymentConfig =     Loadable({ loader: () => import('../config/components/Payment'), loading: LoadingImage });
const AttendanceConfig =  Loadable({ loader: () => import('../config/components/Attendance'), loading: LoadingImage });
const CampaignConfig =    Loadable({ loader: () => import('../config/components/Campaign'), loading: LoadingImage });
const OrderConfig =       Loadable({ loader: () => import('../config/components/Order'), loading: LoadingImage });
const AutomationConfig =  Loadable({ loader: () => import('../config/components/Automation'), loading: LoadingImage });
const LogisticsConfig =   Loadable({ loader: () => import('../config/components/Logistics'), loading: LoadingImage });
const Pharmacies =        Loadable({ loader: () => import('../pharmacies'), loading: LoadingImage });
const PharmacyDetails =   Loadable({ loader: () => import('../pharmacies/details'), loading: LoadingImage });
const Panel =             Loadable({ loader: () => import('../panel'), loading: LoadingImage });
const Finances =          Loadable({ loader: () => import('../finances'), loading: LoadingImage });
const User =              Loadable({ loader: () => import('../user'), loading: LoadingImage });
const UserDetails =       Loadable({ loader: () => import('../user/details'), loading: LoadingImage });

// EXTERNAL ROUTE
const Logon =             Loadable({ loader: () => import('../logon'), loading: LoadingImage });
const EmbedRecipeForm =   Loadable({ loader: () => import('../embedRecipeForm'), loading: LoadingImage });
const DeliveryFeedback =  Loadable({ loader: () => import('../delivery'), loading: LoadingImage });
const PreCheckout =       Loadable({ loader: () => import('../PreCheckout'), loading: LoadingImage });
const Checkout =          Loadable({ loader: () => import('../checkout'), loading: LoadingImage });
const LandingPage =       Loadable({ loader: () => import('../landingPage'), loading: LoadingImage });
const CovidNao =          Loadable({ loader: () => import('../CovidNao'), loading: LoadingImage });

class Content extends React.Component {
  // componentWillMount() {
  //   const { verifyLogin } = this.props;

  //   verifyLogin();
  // }

  detectmob() {
    return (
      navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) ? true : false;
  }

  render() {
    const { authorized, modLinkPag, modAtend } = this.props;
    const isAdm = new StorageDefault().getStorage().admin !== 'false';

    if (authorized && this.detectmob()) {
      return (<NotForMobile />);
    }

    if (authorized) {
      return (
        <>
          <BrowserRouter>
            <Skeleton>
              <MessageModal />
              <Switch>
                <Route exact path="/login" component={withTracker(Dash)} />
                <Route exact path="/" component={withTracker(Dash)} />
                <Route exact path="/dash" component={withTracker(Dash)} />
                <Route exact path="/signup" component={withTracker(Dash)} />
                <Route exact path="/help" component={withTracker(Help)} />
                {modAtend && <Route exact path="/attendance" component={withTracker(Attendance)} />}
                {modAtend && <Route path="/attendance-details" component={withTracker(AttendanceDetails)} />}
                {modLinkPag && <Route path="/operations" component={withTracker(Operations)} />}
                {modLinkPag && <Route path="/expedition" component={withTracker(Expedition)} />}
                {modLinkPag && <Route path="/expedition-details" component={withTracker(ExpeditionDetails)} />}
                {modLinkPag && <Route path="/quotation-details" component={withTracker(QuotationDetails)} />}
                {modLinkPag && <Route path="/production-details" component={withTracker(ProductionDetails)} />}
                {modLinkPag && <Route path="/renegotiation-details" component={withTracker(RenegotiationDetails)} />}
                {modLinkPag && <Route path="/order-details" component={withTracker(OrderDetails)} />}
                <Route path="/client" component={withTracker(Client)} />
                <Route path="/client-details" component={withTracker(ClientDetails)} />
                <Route path="/products" component={withTracker(Products)} />

                {isAdm && (
                  <>
                    <Route path="/config" component={withTracker(Config)} />
                    <Route path="/pharmacies" component={withTracker(Pharmacies)} />
                    <Route path="/pharmacy-details" component={withTracker(PharmacyDetails)} />
                    <Route path="/users" component={withTracker(User)} />
                    <Route path="/user-details" component={withTracker(UserDetails)} />
                    <Route path="/config-integration" component={withTracker(Integration)} />
                    <Route path="/config-attendance" component={withTracker(AttendanceConfig)} />
                    <Route path="/config-campaign" component={withTracker(CampaignConfig)} />
                    <Route path="/config-order" component={withTracker(OrderConfig)} />
                    <Route path="/config-payment" component={withTracker(PaymentConfig)} />
                    <Route path="/config-signature" component={withTracker(SignatureConfig)} />
                    <Route path="/config-automation" component={withTracker(AutomationConfig)} />
                    <Route path="/config-logistics" component={withTracker(LogisticsConfig)} />
                    <Route path="/config-more" component={withTracker(MoreConfig)} />
                    {modLinkPag && <Route path="/finances" component={withTracker(Finances)} />}
                    <Route path="/panel" component={withTracker(Panel)} />
                  </>
                )}

                {APPLY_SANDBOX_VIEW && <Route path="/sandbox" component={SandBox} />}
                <Route component={NotFound} />
              </Switch>
            </Skeleton>
          </BrowserRouter>
        </>
      );
    }

    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route exact path="/popup-image-viewer/:imageUrl" component={withTracker(PopIpImage)} />
            <Route exact path="/embed/:pharmaid/:campaignId" component={withTracker(EmbedRecipeForm)} />
            <Route exact path="/windowed-order-detail/:orderid" component={withTracker(WindowedOrderDetaisl)} />
            <Route exact path="/delivery/:deliveryid/:orderid" component={withTracker(DeliveryFeedback)} />
            <Route exact path="/pre-checkout/:id" component={withTracker(PreCheckout, null, 'mani')} />
            <Route exact path="/alcool-gel" component={withTracker(CovidNao)} />
            <Route exact path="/covid-nao" component={withTracker(CovidNao)} />
            <Route path="/checkout/:type/:id/:vendorId?" component={withTracker(Checkout)} />
            <Route path="/signup" component={withTracker(SignupPharmacy)} />
            <Route path="/login" component={withTracker(Logon)} />
            <Route path="/" component={withTracker(LandingPage)} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  authorized: store.logon.authorized,
  modLinkPag: store.config.modLinkPag,
  modAtend: store.config.modAtend,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ verifyLogin }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Content);
