const initialState = {
  // attendance List
  getListLoading: false,
  list: [],
  queryState: null,
  query: null,
  myTickets: false,
  hasMore: true,
  // attendance List Filter
  users: [],
  channels: [],
  status: [],
  motives: [],
  listError: false,
  // attendance Details
  attendance: {
    canal: null,
    canalId: null,
    cliente: null,
    clienteId: null,
    data: null,
    farmaciaId: null,
    id: null,
    motivo: null,
    motivoId: null,
    numChamado: null,
    responsavel: null,
    responsavelId: null,
    status: null,
    statusId: null,
  },
  getAttendanceDetailsLoading: false,
  tabs: [],
  clearTab: false,
  nextTab: false,
  err: null,
  attendanceUpdated: false,
  orderDrawerOpen: null,
  // comments
  comments: null,
  postCommentLoading: false,
  refreshComments: false,
  newAttendance: null,
  // Tags
  TagList: [],
};

const attendance = (state = initialState, action) => {
  switch (action.type) {
    // Lista de atendimentos
    case 'GET_ATTENDANCE_LIST_LOADING':
      return {
        ...state,
        getListLoading: action.payload,
      };

    case 'GET_ATTENDANCE_PARTIAL_LIST_SUCCESS': {
      const newList = state.list;
      let hasMore = true;

      if (action.payload.list.length <= 0) hasMore = false;

      action.payload.list.map((item) => {
        newList.push(item);
        return true;
      });

      return {
        ...state,
        getListLoading: false,
        list: newList,
        hasMore,
      };
    }

    case 'GET_ATTENDANCE_REFRESH_LIST_SUCCESS':
      return {
        ...state,
        list: action.payload.list,
      };

    case 'GET_ATTENDANCE_LIST_ERROR':
      return {
        ...state,
        getListLoading: false,
        list: [],
        error: 'Ocorreu um erro ao executar a ação.',
      };

    case 'GET_LIST_ATTENDANCE_QUERY_SUCCESS':
      return {
        ...state,
        list: action.payload.list.data,
        hasMore: false,
      };

    case 'ATTENDANCE_REFRESH_QUERY_STATUS':
      return {
        ...state,
        query: action.payload.query,
        queryState: action.payload.queryFields,
        myTickets: action.payload.myTickets,
      };

    case 'CLEAR_ATTENDANCE_LIST':
      return {
        ...state,
        list: [],
        hasMore: true,
      };

    // Listas de opções do filtro
    case 'GET_ATTENDANCE_STATUS_LIST':
      return { ...state, status: action.payload.list };

    case 'GET_ATTENDANCE_CHANNEL_LIST':
      return { ...state, channels: action.payload.list };

    case 'GET_ATTENDANCE_MOTIVE_LIST':
      return { ...state, motives: action.payload.list };

    case 'GET_ATTENDANCE_USER_LIST':
      return { ...state, users: action.payload.list };

    case 'CLEAR_USER_LIST':
      return { ...state, users: initialState.users };

    case 'GET_ATTENDANCE_FILTER_LIST_ERROR':
      return { ...state, listError: !state.listError };

    case 'GET_ATTENDANCE_DETAILS_LOADING':
      return {
        ...state,
        getAttendanceDetailsLoading: action.payload.loading,
      };

    case 'GET_ATTENDANCE_DETAILS_SUCCESS': {
      const { tabs } = state;
      let addtab = true;

      tabs.map((val) => {
        if (val.id === action.payload.id) {
          addtab = false;
        }
        return true;
      });

      if (addtab) {
        tabs.push({
          id: action.payload.id,
          code: (action.payload.cliente.nome || action.payload.cliente.whatsapp),
        });
      }

      return {
        ...state,
        getAttendanceDetailsLoading: false,
        attendanceUpdated: false,
        attendance: {
          ...action.payload,
        },
        tabs,
        newAttendance: null,
      };
    }

    case 'GET_ATTENDANCE_DETAILS_FAILURE':
      return {
        ...state,
        getAttendanceDetailsLoading: false,
        err: action.payload,
      };

    case 'CLEAR_ATTENDANCE_DETAILS':
      return {
        ...state,
        attendance: initialState.attendance,
      };

    case 'SET_ATTENDANCE_STATUS':
      return {
        ...state,
        attendance: {
          ...state.attendance,
          statusId: action.payload.newStatus,
        },
      };

    case 'SET_ATTENDANCE_MOTIVE':
      return {
        ...state,
        attendance: {
          ...state.attendance,
          motivoId: action.payload.newMotive,
        },
      };

    case 'SET_ATTENDANCE_USER':
      return {
        ...state,
        attendance: {
          ...state.attendance,
          responsavelId: action.payload.newUser,
          responsavel: action.payload.nameUser,
        },
      };

    case 'SET_ATTENDANCE_SET_ERROR':
      return {
        ...state,
        setUpdateError: true,
      };

    case 'CLEAR_ATTENDANCE_SET_ERROR':
      return {
        ...state,
        setUpdateError: false,
      };

    // Tabs
    case 'REMOVE_TAB_ATTENDANCE': {
      const tabsOld = state.tabs;
      tabsOld.splice(action.payload, 1);

      if (tabsOld.length <= 0) {
        return {
          ...state,
          tabs: tabsOld,
          clearTab: true,
        };
      }

      return {
        ...state,
        tabs: tabsOld,
        nextTab: true,
      };
    }

    case 'CLEAR_CLEAR_TAB_ATTENDANCE':
      return {
        ...state,
        clearTab: false,
      };

    case 'CLEAR_NEXT_TAB_ATTENDANCE':
      return {
        ...state,
        nextTab: false,
      };

    case 'ATTENDANCE_ORDER_OPEN':
      return {
        ...state,
        orderDrawerOpen: action.payload,
      };

    case 'ATTENDANCE_CLEAR_ORDER_OPEN':
      return {
        ...state,
        orderDrawerOpen: null,
      };

    // Comments
    case 'ATTENDANCE_COMMENTS_SUCCESS':
      return {
        ...state,
        comments: action.payload,
        refreshComments: false,
      };

    case 'ATTENDANCE_COMMENTS_ERROR':
      return {
        ...state,
        comments: action.payload,
        refreshComments: false,
      };

    case 'ATTENDANCE_POST_COMMENT_LOADING':
      return {
        ...state,
        postCommentLoading: true,
      };

    case 'ATTENDANCE_POST_COMMENT_SUCCESS':
      return {
        ...state,
        postCommentLoading: false,
        refreshComments: true,
      };

    case 'ATTENDANCE_POST_COMMENT_ERROR':
      return {
        ...state,
        postCommentLoading: false,
      };

    case 'ATTENDANCE_POST_SUCCESS':
      return {
        ...state,
        newAttendance: action.payload,
      };

    case 'ATTENDANCE_SET_QUERY_STATUS':
      return {
        ...state,
        queryOpen: action.payload.status,
        hasMore: !action.payload.status,
        queryState: action.payload.queryState,
        query: action.payload.query,
      };

    case 'ATTENDANCE_UPDATE_TABS':
      return {
        ...state,
        tabs: action.payload,
      };

    case 'SET_ATTENDANCE_CHANNEL':
      return {
        ...state,
        attendance: {
          ...state.attendance,
          canalId: action.payload.channelId,
          canal: action.payload.channel,
        },
      };

    case 'ATTENDANCE_GET_ALL_TAGS_SUCCESS':
      return {
        ...state,
        tagList: action.payload,
      };


    case 'UPDATE_ATTENDANCE_TAGS_LOADING':
      return {
        ...state,
        updateTagLoading: true,
      };

    case 'UPDATE_ATTENDANCE_TAGS_SUCCESS': {
      const tagList = state.attendance.tags;

      tagList.push(action.payload);

      return {
        ...state,
        attendance: {
          ...state.attendance,
          tags: tagList,
        },
        updateTagLoading: false,
      };
    }

    case 'UPDATE_ATTENDANCE_TAGS_ERROR':
      return {
        ...state,
        updateTagLoading: false,
      };

    case 'DELETE_ATTENDANCE_TAGS_SUCCESS': {
      const tagList = [];

      state.attendance.tags.map((item) => {
        if (item.id !== action.payload) tagList.push(item);

        return true;
      });

      return {
        ...state,
        attendance: {
          ...state.attendance,
          tags: tagList,
        },
        updateTagLoading: false,
      };
    }

    default:
      return { ...state };
  }
};

export default attendance;
