import React from 'react';
import { TRACKING_MANI_ID, OPTIMIZER_ID } from '../../constants/apiBase';

class OptimizerProvider extends React.Component {
  constructor(props) {
    super(props);
    if (!window.OptimizedInjetado) {
      const script = document.createElement('script');
      script.src = `https://www.googleoptimize.com/optimize.js?id=${OPTIMIZER_ID}`;
      script.async = true;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      this.gtag('js', new Date());

      this.gtag('config', TRACKING_MANI_ID, { optimize_id: OPTIMIZER_ID });
    }

    window.OptimizedInjetado = true;
  }

  gtag() {
    window.dataLayer.push(arguments)
  }

  render() {
    const { children } = this.props;

    return (<>{children}</>)
  }
}

export default OptimizerProvider;