const initialState = {
  getManiLoading: false,
  numPedidoMani: null,
  maniOffers: [],
  maniOffersEmpty: [],
  loadingCupom: false,
  cupomNotValid: null,
  cupomCheck: {},
  qtdParticipantes: 0,
  qtdDesistiram: 0,
  isRdEnviroment: null,
  pedidoCancelado: false,
}

const preCheckout = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MANI_ORDER_LOADING':
      return {
        ...state,
        getManiLoading: true,
      };
    case 'GET_MANI_ORDER_SUCCESS':
      return{
        ...state,
        getManiLoading: false,
        maniOffers: action.payload.cotacoes,
        numPedidoMani: action.payload.numPedidoMani,
        qtdParticipantes: action.payload.qtdFarmaciasParticipantes,
        qtdDesistiram: action.payload.qtdFarmaciasDesistiram,
        maniOffersEmpty: action.payload.cotacoesSemOrcamento,
        isRdEnviroment: action.payload.ambienteRD,
        pedidoCancelado: action.payload.pedidoCancelado
      };
    case 'GET_MANI_ORDER_ERROR':
      return {
        ...state,
        getManiLoading: false,
      };

    // Cupom Validation
    case 'PRECHECKOUT_VALIDATE_CUPON_LOADING':
      return {
        ...state,
        loadingCupom: true,
      };
    case 'PRECHECKOUT_VALIDATE_CUPON_SUCCESS':
      return {
        ...state,
        loadingCupom: false,
        cupomNotValid: false,
        cupomCheck: action.payload,
      };
    case 'PRECHECKOUT_VALIDATE_CUPON_ERROR':
      return {
        ...state,
        loadingCupom: false,
        cupomNotValid: true,
        cupomCheck: action.payload,
      }
  
    default:
      return { ...state };
  }
}

export default preCheckout;