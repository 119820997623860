import React from "react";

import {CircularProgress} from "@material-ui/core";

import style from "./style.css";

class LoadingImage extends React.Component {
  render(){
    return (
      <div className={style.baseLoading}>
        <img src={"/images/logo_mini.png"} alt="Logo Miniatura" />
        <CircularProgress /> 
      </div>
    )
  }
}

export default LoadingImage;