const generalStatusList = [
  { id: 'dde13b2a-d249-4ad9-8c83-4309ed03dae8', titulo: 'Ag. Orçamento' },
  { id: 'c163acab-2bac-4052-a847-4f0f45f15e7f', titulo: 'Orçamento Pronto' },
  { id: '7f916e81-4e1c-4f4c-aea9-6e9c710691a4', titulo: 'Em Negociação' },
  // { id: '40d78245-96ce-48ff-ad1c-b120ed29b23e', titulo: 'Perdido' },
  { id: 'b5c7346f-195b-4aec-a597-175af663589b', titulo: 'Rejeitado' },
  { id: '6ea38b67-6b73-448d-897d-34b7f57b8f84', titulo: 'Ag. Pagamento' },
  { id: '8930a925-6f30-4bf5-ad6a-e3ccd3da52b2', titulo: 'Confirmado' },
  { id: 'edbdf1c1-e6d9-463d-80df-311a223d30cc', titulo: 'Em produção' },
  { id: 'f6db6f53-0d97-46fe-94ca-68658e58a958', titulo: 'Concluído' },
  { id: '1bee8583-2405-4efc-920d-ebfbf753af37', titulo: 'Cancelado' },
];

const quotationStatusList = [
  { id: 'dde13b2a-d249-4ad9-8c83-4309ed03dae8', titulo: 'Ag. Orçamento' },
  { id: 'c163acab-2bac-4052-a847-4f0f45f15e7f', titulo: 'Orçamento Pronto' },
  { id: '7f916e81-4e1c-4f4c-aea9-6e9c710691a4', titulo: 'Em Negociação' },
];

const renegotiationStatusList = [
  // { id: '40d78245-96ce-48ff-ad1c-b120ed29b23e', titulo: 'Perdido' },
  { id: 'b5c7346f-195b-4aec-a597-175af663589b', titulo: 'Rejeitado' },
];
const orderStatusList = [
  { id: '6ea38b67-6b73-448d-897d-34b7f57b8f84', titulo: 'Ag. Pagamento' },
  { id: '8930a925-6f30-4bf5-ad6a-e3ccd3da52b2', titulo: 'Confirmado' },
  { id: 'f6db6f53-0d97-46fe-94ca-68658e58a958', titulo: 'Concluído' },
  { id: '1bee8583-2405-4efc-920d-ebfbf753af37', titulo: 'Cancelado' },
];

const initialState = {
  atualGridtab: 0,
  listQuotation: [],
  listRenegotation: [],
  listOrder: [],
  listProduction: [],
  list: [],
  queryState: null,
  query: null,
  myOrders: false,
  statusList: generalStatusList,
  statusListQuotation: quotationStatusList,
  statusListOrder: orderStatusList,
  statusListRenegotiation: renegotiationStatusList,
  hasMore: true,
  myItensSelect: false,
  getListInfinityLoading: false,
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case 'OPERATIONS_GET_LIST_LOADING':
      return {
        ...state,
        getListInfinityLoading: true,
      };

    case 'OPERATIONS_GET_LIST_SUCCESS': {
      let newList = null;
      switch (action.payload.tab) {
        case 1: newList = state.listQuotation; break;
        case 2: newList = state.listRenegotation; break;
        case 3: newList = state.listOrder; break;
        case 4: newList = state.listProduction; break;
        case 5:
        default:
          newList = state.list;
      }
      let hasMore = true;

      if (action.payload.data.length <= 0) {
        hasMore = false;
      }

      newList = newList.concat(action.payload.data);

      switch (action.payload.tab) {
        case 1:
          return {
            ...state,
            listQuotation: newList,
            hasMore,
            getListInfinityLoading: false,
          };
        case 2:
          return {
            ...state,
            listRenegotation: newList,
            hasMore,
            getListInfinityLoading: false,
          };
        case 3:
          return {
            ...state,
            listOrder: newList,
            hasMore,
            getListInfinityLoading: false,
          };
        case 4:
          return {
            ...state,
            listProduction: newList,
            hasMore,
            getListInfinityLoading: false,
          };
        case 5:
        default:
          return {
            ...state,
            list: newList,
            hasMore,
            getListInfinityLoading: false,
          };
      }
    }
    case 'OPERATIONS_GET_REFRESH_LIST_SUCCESS': {
      switch (action.payload.tab) {
        case 1:
          return {
            ...state,
            listQuotation: action.payload.data,
          };
        case 2:
          return {
            ...state,
            listRenegotation: action.payload.data,
          };
        case 3:
          return {
            ...state,
            listOrder: action.payload.data,
          };
        case 4:
          return {
            ...state,
            listProduction: action.payload.data,
          };
        case 5:
        default:
          return {
            ...state,
            list: action.payload.data,
          };
      }
    }
    case 'OPERATIONS_GET_LIST_ERROR':
      return {
        ...state,
        getListInfinityLoading: false,
      };

    case 'OPEATIONS_REFRESH_QUERY_STATUS':
      return {
        ...state,
        query: action.payload.query,
        queryState: action.payload.queryFields,
        myOrders: action.payload.myOrders,
      };

    case 'OPERATIONS_CLEAR_LIST':
      return {
        ...state,
        listQuotation: [],
        listRenegotation: [],
        listOrder: [],
        listProduction: [],
        list: [],
        hasMore: true,
      };

    case 'OPERATION_SET_TAB':
      return {
        ...state,
        atualGridtab: action.payload,
      };

    case 'OPERATIONS_POP_EXCLUDE_ITEM': {
      const newList = [];

      if (state.list) {
        state.list.map((item) => {
          if (item.id !== action.payload) {
            newList.push(item);
          }
          return true;
        });
      }

      return {
        ...state,
        listQuotation: newList,
      };
    }

    default:
      return { ...state };
  }
};

export default order;
