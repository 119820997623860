const initialState = {
  config: {
    id: null,
    integracaoFc: false,
    integracaoWhatsAppNumero: '',
    integracaoWhatsAppToken: '',
    integracaoWhatsAppAtivado: false,
    utilizarMaxiPago: false,
    maxiPagoBoletoProcessorId: null,
    maxiPagoMerchantId: null,
    maxiPagoMerchantKey: null,
    maxiPagoProcessorId: null,
    wirecardBankAccountId: null,
    wirecardBankAccountStatus: null,
    wirecardBankAccountLink: null,
    wirecardAccountId: null,
    wirecardAccountToken: null,
    maxiPagoDiasBoleto: 0,
    numParcelaCartao: 0,
    modLinkPag: null,
    modAtend: null,
    urlService: null,
    permitirBoleto: false,
    permitirTransferencia: false,
    permitirCartao: false,
    permitirPagarNaEntrega: false,
    permitirRetirarNaLoja: false,
    enviarOfertaNoMatch: false,
    msgEnvioOferta: null,
    msgReEnvioDesconto: null,
    horasMarcarPedidoPerdido: null,
  },
  emailConfig: {
    farmaciaId: null,
    smtpServer: null,
    smtpPort: null,
    smtpUsername: null,
    smtpPassword: null,
    popPort: null,
    popServer: null,
    popUsername: null,
    popPassword: null,
  },
  popTest: null,
  smtpTest: null,
  pharmacyConfigError: false,
  loadingConfigs: false,
  loadingApi: false,
  error: null,
  formulaCerta: null,
  manipulaae: null,
  notifications: {
    chamadosAtrasados: 0,
    pedidosEmNegociacao: 0,
    tempoConfiguradoPedidosVisualizados: 0,
  },
  parcelamento: [],
  panelLink: null,
  cepRange: [],
  cepRangeNoDelivery: [],
  bankAccountList: [],
  bankProviders: [],
  // modules
  modLinkPag: false,
  modAtend: false,
  gridReneagociacaoAtiva: false,
  waboxAtivo: null,
  // tags
  tagList: [],
  tagListLoading: false,
  // pharmacy Color
  pharmacyColor: '#006ED1',
  // campaign
  campaignList: [],
  loadingCampaign: false,
};

const integration = (state = initialState, action) => {
  switch (action.type) {
    // Configuracao da Farmacia
    case 'PARMACY_CONFIG_LOADING':
      return {
        ...state,
        loadingConfigs: true,
      };

    case 'PHARMACY_CONFIG_SUCCESS': {
      if (action.payload.updateMods) {
        return {
          ...state,
          config: action.payload.data,
          modLinkPag: action.payload.data.modLinkPag,
          modAtend: action.payload.data.modAtend,
          loadingConfigs: false,
        };
      }

      return {
        ...state,
        config: action.payload.data,
        loadingConfigs: false,
      };
    }

    case 'PHARMACY_CONFIG_ERROR':
      return {
        ...state,
        config: initialState.config,
        pharmacyConfigError: true,
        loadingConfigs: false,
      };

    case 'CLEAR_PHARMACY_CONFIG_ERROR':
      return {
        ...state,
        pharmacyConfigError: false,
      };

    // Integracao
    case 'LOADING_INTEGRACAO':
      return {
        ...state,
        loadingApi: action.payload,
      };

    case 'GET_FORMULACERTA_ERROR':
      return {
        ...state,
        loadingApi: false,
        error: action.payload,
      };

    case 'GET_FORMULACERTA_SUCCESS':
      return {
        ...state,
        loadingApi: false,
        formulaCerta: action.payload,
        error: null,
      };

    case 'GET_MANIPULAE_SUCCESS':
      return {
        ...state,
        loadingApi: false,
        manipulae: action.payload,
        error: null,
      };

    case 'GET_MANIPULAE_ERROR':
      return {
        ...state,
        loadingApi: false,
        error: action.payload,
      };

    case 'IINTEGRATION_GET_NOTIFICATION':
      return {
        ...state,
        notifications: action.payload,
        waboxAtivo: action.payload.waboxAtivo,
      };

    case 'IINTEGRATION_GET_ACTIVE_MODULES_SUCCESS':
      return {
        ...state,
        panelLink: action.payload.metabase,
        modLinkPag: action.payload.modLinkPag,
        modAtend: action.payload.modAtend,
        gridReneagociacaoAtiva: action.payload.gridReneagociacaoAtiva,
        pharmacyColor: action.payload.corCheckout,
      };

    case 'INTEGRATION_UPDATE_MODULES': {
      if (action.payload.updateMods) {
        return {
          ...state,
          modLinkPag: action.payload.modLinkPag,
          modAtend: action.payload.modAtend,
          gridReneagociacaoAtiva: action.payload.gridReneagociacaoAtiva,
          config: {
            ...state.config,
            ...action.payload.data,
          },
        };
      }

      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload.data,
        },
      };
    }
    case 'CONFIG_GET_PARCELAS_CARTAO_SUCCESS':
      return {
        ...state,
        parcelamento: action.payload,
      };

    case 'CONFIG_SET_PARCELAS_CARTAO_LOADING':
      return {
        ...state,
        loadingConfigs: true,
      };

    case 'CONFIG_SET_PARCELAS_CARTAO_SUCCESS':
      return {
        ...state,
        parcelamento: action.payload,
        loadingConfigs: false,
      };

    case 'CONFIG_SET_PARCELAS_CARTAO_ERROR':
      return {
        ...state,
        loadingConfigs: false,
      };

    case 'CONFIG_GET_CEP_RANGE_SUCCESS':
      return {
        ...state,
        cepRange: action.payload,
      };

    case 'CONFIG_POST_BANK_ACCOUNTS_SUCCESS':
    case 'CONFIG_GET_BANK_ACCOUNTS_SUCCESS':
      return {
        ...state,
        bankAccountList: action.payload,
      };

    case 'CONFIG_POST_CEP_NO_DELIVEY_RANGE_SUCCESS':
    case 'CONFIG_GET_CEP_NO_DELIVEY_RANGE_SUCCESS':
      return {
        ...state,
        cepRangeNoDelivery: action.payload,
      };

    case 'CONFIG_GET_EMAIL_CONFIG':
      return {
        ...state,
        emailConfig: action.payload,
      };

    case 'CONFIG_POP_TEST':
      return {
        ...state,
        popTest: action.payload,
      };

    case 'CONFIG_SMTP_TEST':
      return {
        ...state,
        smtpTest: action.payload,
      };

    case 'CONFIG_GET_ALL_TAGS_LOADING':
      return {
        ...state,
        tagListLoading: true,
      };

    case 'CONFIG_GET_ALL_TAGS_SUCCESS':
      return {
        ...state,
        tagList: action.payload,
        tagListLoading: false,
      };

    case 'CONFIG_GET_ALL_TAGS_ERROR':
      return {
        ...state,
        tagListLoading: false,
      };

    case 'CONFIG_SAVENEW_TAG_LOADING':
      return {
        ...state,
        tagListLoading: true,
      };

    case 'CONFIG_SAVENEW_TAG_SUCCESS': {
      const newTagList = state.tagList;
      newTagList.push(action.payload);

      return {
        ...state,
        tagList: newTagList,
        tagListLoading: false,
      };
    }

    case 'CONFIG_SAVENEW_TAG_ERROR':
      return {
        ...state,
        tagListLoading: false,
      };

    case 'CONFIG_DELETE_TAG_LOADING':
      return {
        ...state,
        tagListLoading: true,
      };

    case 'CONFIG_DELETE_TAG_SUCCESS': {
      const newTagList = [];

      state.tagList.map((item) => {
        if (item.id !== action.payload) {
          newTagList.push(item);
        }
        return true;
      });

      return {
        ...state,
        tagList: newTagList,
        tagListLoading: false,
      };
    }

    case 'CONFIG_DELETE_TAG_ERROR':
      return {
        ...state,
        tagListLoading: false,
      };

    case 'CLEAR_TAG_LIST':
      return {
        ...state,
        tagList: [],
      }

    case 'CONFIG_GET_ALL_BANCK_PROVIDERS':
      return {
        ...state,
        bankProviders: action.payload,
      };

    case 'CONFIG_GET_ALL_CAMPING_ITEMS_LOADING':
      return {
        ...state,
        loadingCampaign: true,
      };

    case 'CONFIG_GET_ALL_CAMPING_ITEMS_SUCCESS':
      return {
        ...state,
        loadingCampaign: false,
        campaignList: action.payload,
      };

    case 'CONFIG_GET_ALL_CAMPING_ITEMS_ERROR':
      return {
        ...state,
        loadingCampaign: false,
      };

    case 'CONFIG_POST_NEW_CAMPING_SUCCESS':
      return {
        ...state,
        campaignList: state.campaignList.concat(action.payload),
      };

    // case 'CONFIG_POST_CAMPING_ACESS_COUNT_SUCCESS':
    //   return {
    //     ...state,
    //   };

    // case 'CONFIG_POST_CAMPING_ACESS_COUNT_ERROR':
    //   return {
    //     ...state,
    //   };

    default: return { ...state };
  }
};

export default integration;
