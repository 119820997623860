const initialState = {
  // Finances List
  loadList: false,
  loadQuery: false,
  queryOpen: false,
  queryState: null,
  query: null,
  hasMore: true,
  list: [],
  statusList: [],
  // Balance Value
  balenceLoading: false,
  balanceError: null,
  balance: null,
  currentBalance: 0,
  futureBalance: 0,
  blockedBalance: 0,
  withdrawliberate: false,
  // Withdraw
  withdrawLoading: false,
  // refound
  refoundLoading: false,
  // Report List
  financesReportListLoagind: false,
  financesReportList: [],
  // Bank Statement
  bankStatementLoading: false,
  bankStatementList: [],
  bankStatementTags: [],
  // Anticipation
  anticipationLoading: false,
  anticipationDetails: {},
  anticipationProcessLoading: false,
  // Charge Hystory
  chargeHistoryLoading: false,
  chargeHistory: null,
  chargeCount: 0,
};

const finance = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BALANCE_LOADING':
      return ({
        ...state,
        balenceLoading: true,
      });

    case 'GET_BALANCE_SUCCESS':
      return ({
        ...state,
        balenceLoading: false,
        balance: action.payload.data.Balance,
        withdrawliberate: action.payload.data.saqueLiberado,
      });

    case 'GET_BALANCE_ERROR':
      return ({
        ...state,
        balenceLoading: false,
        balanceError: action.payload.err,
        withdrawliberate: action.payload.saqueLiberado,
      });

    case 'GET_WIRECARD_BALANCE_SUCCESS':
      return ({
        ...state,
        balenceLoading: false,
        currentBalance: action.payload.data.saldoAtual,
        futureBalance: action.payload.data.saldoFuturo,
        blockedBalance: action.payload.data.saldoBloqueado,
      });

    case 'WITHDRAW_LOADING':
      return ({
        ...state,
        withdrawLoading: true,
      });

    case 'WITHDRAW_SUCCESS':
      return ({
        ...state,
        withdrawLoading: false,
      });

    case 'WITHDRAW_ERROR':
      return ({
        ...state,
        withdrawLoading: false,
      });

    case 'GET_FINANCES_LIST_LOADING':
      return ({
        ...state,
        loadList: true,
      });

    case 'GET_FINANCES_LIST_SUCCESS': {
      let newList = state.list;
      let hasMore = true;

      if (action.payload.data.length <= 0) {
        hasMore = false;
      }

      newList = newList.concat(action.payload.data);

      return ({
        ...state,
        list: newList,
        hasMore,
        balanceError: null,
        loadList: false,
      });
    }

    case 'GET_FINANCES_LIST_ERROR':
      return ({
        ...state,
        balanceError: action.payload.err,
        loadList: false,
      });

    case 'GET_FINANCES_INTERVAL_SUCCESS': {
      if (action.payload.data[0].id === state.list[0].id) {
        return { ...state };
      }

      const newList = action.payload.data.concat(state.list);

      return {
        ...state,
        list: newList,
      };
    }

    case 'GET_FINANCES_LIST_QUERY_LOADING':
      return {
        ...state,
        hasMore: false,
        loadQuery: true,
      };

    case 'GET_FINANCES_LIST_QUERY_SUCCESS':
      return {
        ...state,
        list: action.payload.list.data,
        hasMore: false,
        loadQuery: false,
      };

    case 'GET_FINANCES_LIST_QUERY_ERROR':
      return {
        ...state,
        hasMore: false,
        loadQuery: false,
      };


    case 'CLEAR_FINANCES_LIST':
      return {
        ...state,
        list: [],
        hasMore: true,
      };

    case 'GET_STATUS_LIST_FINANCES':
      return {
        ...state,
        statusList: action.payload,
      };

    case 'FINANCES_SET_QUERY_STATUS':
      return {
        ...state,
        queryOpen: action.payload.status,
        hasMore: !action.payload.status,
        queryState: action.payload.queryState,
        query: action.payload.query,
      };

    case 'FINANCES_REFOUND_LOADING':
      return {
        ...state,
        refoundLoading: true,
      };

    case 'FINANCES_REFOUND_SUCCESS':
      return {
        ...state,
        refoundLoading: false,
      };

    case 'FINANCES_REFOUND_ERROR':
      return {
        ...state,
        refoundLoading: false,
      };

    case 'FINANCES_GET_REPORT_LIST_LOADING':
      return {
        ...state,
        financesReportListLoagind: true,
      };

    case 'FINANCES_GET_REPORT_LIST':
      return {
        ...state,
        financesReportListLoagind: false,
        financesReportList: action.payload,
      };

    case 'FINANCES_GET_BANK_STATEMENT_LOADING':
      return {
        ...state,
        bankStatementLoading: true,
      };

    case 'FINANCES_GET_BANK_STATEMENT_SUCCESS':
      return {
        ...state,
        bankStatementLoading: false,
        bankStatementList: action.payload,
      };

    case 'FINANCES_GET_BANK_STATEMENT_ERROR':
      return {
        ...state,
        bankStatementLoading: false,
      };

    case 'FINANCES_CLEAR_STATEMENT_LIST':
      return {
        ...state,
        bankStatementList: [],
      };

    case 'FINANCES_GET_STATEMENT_TAGS_SUCCESS':
      return {
        ...state,
        bankStatementTags: action.payload,
      };

    case 'FINANCES_GET_ANTICIPATION_DETAILS_LOADING':
      return {
        ...state,
        anticipationLoading: true,
      };
    
    case 'FINANCES_GET_ANTICIPATION_DETAILS_SUCCESS':
      return {
        ...state,
        anticipationLoading: false,
        anticipationDetails: action.payload,
      };

    case 'FINANCES_GET_ANTICIPATION_DETAILS_ERROR':
      return {
        ...state,
        anticipationLoading: false,
      };

    case 'FINANCES_POST_ANTICIPATION_LOADING':
      return {
        ...state,
        anticipationProcessLoading: true,
      }

    case 'FINANCES_POST_ANTICIPATION_SUCCESS':
    case 'FINANCES_POST_ANTICIPATION_ERROR':
      return {
        ...state,
        anticipationProcessLoading: false,
      };

    case 'FINANCES_GET_CHAGE_HISTORY_LOADING':
      return {
        ...state,
        chargeHistoryLoading: true,
      };

    case 'FINANCES_GET_CHAGE_HISTORY_SUCCESS':
      return {
        ...state,
        chargeHistoryLoading: false,
        chargeHistory: action.payload,
      };

    case 'FINANCES_GET_CHAGE_HISTORY_ERROR':
      return {
        ...state,
        chargeHistoryLoading: false,
        chargeHistory: null,
      };

    case 'FINANCES_CLEAR_CHARGE_HISTORY':
      return {
        ...state,
        chargeHistoryLoading: false,
        chargeHistory: null,
      }

    case 'FINANCES_GET_CHARGE_ITEMS_COUNT':
      return {
        ...state,
        chargeCount: action.payload,
      }

    default:
      return { ...state };
  }
};

export default finance;
