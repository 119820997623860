export const HEADER_REQUEST = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  },
};

export const HEADER_REQUEST_FORM = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
  },
};

export const API_HEADER = {
  headers: {
    key: "123",
  },
};

//           ---- API ----
const enviroment = window.location.hostname;

const GATEWAY_DEV = "http://gateway.docker.internal";
const GATEWAY_TEST = "https://gateway-test.manipulae.com.br";
const GATEWAY_STAGE = "https://gateway-stage.manipulae.com.br";
const GATEWAY_PROD = "https://gateway.manipulae.com.br";

let apiUrl;
let doamin;
let doaminCheckout;
let domainCheckoutGateway;
let applySandboxView;
let orgId;
let maniApi;
let gateway;

switch (enviroment) {
  case "localhost":
    apiUrl = "http://gateway.docker.internal/rocket";
    doamin = "http://localhost:3003";
    doaminCheckout = `${apiUrl}/checkout`;
    domainCheckoutGateway = `${apiUrl}/checkout/gateway`;
    applySandboxView = false;
    orgId = "1snn5n9w";
    maniApi = GATEWAY_DEV + "/api";
    gateway = GATEWAY_DEV;
    break;

  case "stage.pharmarocket.com":
  case "rocket-webapp---stage.purpleplant-169ac7ca.brazilsouth.azurecontainerapps.io":
    apiUrl = "https://gateway-stage.manipulae.com.br/rocket";
    doamin = "https://stage.pharmarocket.com";
    doaminCheckout = "https://stage.pharmarocket.com/checkout";
    domainCheckoutGateway = "https://stage.pharmarocket.com/checkout/gateway";
    applySandboxView = true;
    orgId = "1snn5n9w";
    maniApi = GATEWAY_STAGE + "/api";
    gateway = GATEWAY_STAGE;
    break;

  case "trial.pharmarocket.com":
    apiUrl = "https://pharma-rocket-api-trial.azurewebsites.net/api";
    doamin = "https://trial.pharmarocket.com";
    doaminCheckout = "https://trial.pharmarocket.com/checkout";
    domainCheckoutGateway = "https://trial.pharmarocket.com/checkout/gateway";
    applySandboxView = false;
    orgId = "1snn5n9w";
    maniApi = GATEWAY_STAGE + "/api";
    gateway = GATEWAY_STAGE;
    break;

  case "pharma-rocket-webapp-dev.azurewebsites.net":
    apiUrl = "https://gateway-test.manipulae.com.br/rocket";
    doamin = "https://pharma-rocket-webapp-dev.azurewebsites.net";
    doaminCheckout = `${apiUrl}/checkout`;
    domainCheckoutGateway = `${apiUrl}/checkout/gateway`;
    applySandboxView = false;
    orgId = "1snn5n9w";
    maniApi = GATEWAY_DEV + "/api";
    gateway = GATEWAY_DEV;
    break;

  case "pharma-rocket-webapp-test.azurewebsites.net":
  case "rocket-webapp-test.bravemeadow-0951fae4.westus.azurecontainerapps.io":
  case "rocket-test.manipulae.com.br":
    apiUrl = "https://gateway-test.manipulae.com.br/rocket";
    doamin = "https://pharma-rocket-webapp-test.azurewebsites.net";
    doaminCheckout = `${apiUrl}/checkout`;
    domainCheckoutGateway = `${apiUrl}/checkout/gateway`;
    applySandboxView = false;
    orgId = "1snn5n9w";
    maniApi = GATEWAY_TEST + "/api";
    gateway = GATEWAY_TEST;
    break;

  case "pharmarocket.com":
  case "rocket-webapp---production.purpleplant-169ac7ca.brazilsouth.azurecontainerapps.io":
    apiUrl = "https://gateway.manipulae.com.br/rocket";
    doamin = "https://www.pharmarocket.com";
    doaminCheckout = "https://www.pharmarocket.com/checkout";
    domainCheckoutGateway = "https://www.pharmarocket.com/checkout/gateway";
    applySandboxView = false;
    orgId = "k8vif92e";
    maniApi = GATEWAY_PROD + "/api";
    gateway = GATEWAY_PROD;
    break;

  default:
    apiUrl = "https://gateway.manipulae.com.br/rocket";
    doamin = "https://www.pharmarocket.com";
    doaminCheckout = "https://www.pharmarocket.com/checkout";
    domainCheckoutGateway = "https://www.pharmarocket.com/checkout/gateway";
    applySandboxView = false;
    orgId = "k8vif92e";
    maniApi = GATEWAY_PROD + "/api";
    gateway = GATEWAY_PROD;
}

export const API_URL = apiUrl;
export const DOMAIN = doamin;
export const DOMAIN_CHECKOUT = doaminCheckout;
export const DOMAIN_CHECKOUT_GATEWAY = domainCheckoutGateway;
export const APPLY_SANDBOX_VIEW = applySandboxView;
export const ORG_ID = orgId || "k8vif92e";
export const MANI_API_URL = maniApi;
export const GATEWAY = gateway;

let isProd;
switch (enviroment) {
  case "devBack":
  case "localhost":
  case "stage.pharmarocket.com":
  case "trial.pharmarocket.com":
  case "pharma-rocket-webapp-dev.azurewebsites.net":
  case "pharma-rocket-webapp-test.azurewebsites.net":
    isProd = false;
    break;
  case "pharmarocket.com":
    isProd = true;
    break;
  default:
    isProd = false;
}

export const IS_PROD = isProd;

// Chave Google Analitycs
export const TRACKING_MANI_ID = "UA-88592324-1";

// Chave Google Optimizer
export const OPTIMIZER_ID = "OPT-NN7HH5Q";

// intervalos
export const SNACK_TIME_OUT = 3000;
export const TIME_INTERVAL_NOTIFICATIONS = 5 * 60 * 1000;
export const TIME_INTERVAL_ORDERS = 15000;
export const TIME_INTERVAL_ORDERS_CARD = 30 * 1000;
export const TIME_INTERVAL_WHATSAPP_REFRESH = 5 * 1000;
export const TIME_INTERCAL_CHECK_UNREAD_MENSAGES = 5 * 1000;

// Supoerte a parcelamento
export const MAX_PARCELAS = 6;
export const VALOR_MINIMO_PARA_PARCELAR = 12;
export const TIPO_PARCELAMENTO = "N";

// Produção
export const pubKeyMoip = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjEIPNROJ2OvtsLo5fiAs
I6oYd/3QQpu810+KsYlT3ZJbkZrw/c69jQoZJaJ0zyPRNtOmdY1uMip9NJCX6Fk/
Bke2MxfPLixzHqsnaVsOpiTl/yiCt5h8LqcnicnZYBDEkUwXIv+VZco2+AizgfeN
upbb8N9X9FQDXj0VJGU8S/mJsujVg3WiFKsSSnEpcUF9n0qZ1SAfnULuwj6kRCXk
qVkUIn7a+tvVaufzs1vTQUEjOz+UxeyVPX/0EVxescxHzGD3iR9jZC3FLn4UkMpo
lkQ1i+xcJRu8r7XsN7R5E4ApyjOsKypOMBv3ToF7TdlKoWpT5dFCWAW80PXupMKy
CwIDAQAB
-----END PUBLIC KEY-----`;

export const pubKeyMoipMani = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhgyslOzDbylBfhBxrP5p
84gjpNPry69p441VWPFfjISFmfRd0qSv3Le/2u+MzjM/lweN0OJxJF1yauxs4nVG
qr8Qf7H+SjN0EOh9DiMxVVOLbwPQC2U7HwQ19W9ZOMcu6BrI0/2iKuaO3wUW7xvG
kAgT/Vauerazfn4Zprfjky6HVNoad4nx3mqex40F/stKs6xBQCRmtgmD+KSPw2Or
1rjccdfNP+AFCOzXHHGGIt+hl+GYgeWU07YE7uOEx7xnoxmrrewQCOF1AgjH35ux
mCUYqkqlZmDx/E0E2Ac9xWWUCnF9keSJCNUf2Bn8la6t2XPiPfPVSwQhH27VPZUk
swIDAQAB
-----END PUBLIC KEY-----`;

// dev
// export const pubKeyMoip = `-----BEGIN PUBLIC KEY-----
// MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAo3+VvRahVPJFXXQVQg30
// nrgmZrtTPJm0atPZvkiIxAhnyeX20cZPijNQt4usiX/WgGIFiz+oBSmXu5oShy4A
// gXJ7h8DhhdiHAGjP41Ckg8WYh+Liz154GaG/iwZqHUYOCZcX7r1JlTGkwwCxLyvn
// +C0KQPAO/KKaR/+fC14uta1SFLFQqY6rKshJjtILLVeh4ZQTPw1YJsfGQEPGiePk
// kwKjszdB70jDx9Xheu2XfbID7F1/kXavtyMivOg3hplRvQWlwVEgVvBcgmG5vi0t
// lYBUcUjFcWirp61kXF4Gf5XGUlo8Nd+MjYco+5eG/bjgcJfXqmd9ZJAWzErmW7l+
// dQIDAQAB
// -----END PUBLIC KEY-----`;

export const SIGNIN_USERS = `${API_URL}/login`;

// Address
export const PUT_ADDRESS = `${API_URL}/Endereco`;
export const GET_UF = `${API_URL}/endereco/estados`;
export const GET_ADDRESS = `${API_URL}/endereco/GetAll`;
export const GET_CITIES = `${API_URL}/endereco/cidades`;
export const GET_ADDRESS_BY_CEP_URL = `${API_URL}/Endereco/cep`;
export const POST_ADDRESS_RANGE_CONGIF = `${API_URL}/Endereco/PostFaixasCep`;
export const GET_ADDRESS_RANGE_CONGIF = `${API_URL}/Endereco/GetFaixasCep`;
export const GET_ADDRESS_RANGE = `${API_URL}/Endereco/ValorFrete`;

// Attendance
export const GET_PAGE_ATTENDANCE = `${API_URL}/chamado/GetPage`;
export const GET_ATTENDANCE_CHANNEL = `${API_URL}/chamado/canal`;
export const GET_ATTENDANCE_STATUS = `${API_URL}/chamado/status`;
export const GET_ATTENDANCE_MOTIVO = `${API_URL}/chamado/motivo`;
export const GET_ATTENDANCE_USER = `${API_URL}/chamado/responsaveis`;
export const POST_ATTENDANCE_USER = `${API_URL}/chamado/responsavel`;
export const ATTENDANCE_PAGE = `${API_URL}/chamado/ResumoChamadosFarmacia`;
export const ATTENDANCE_DETAILS = `${API_URL}/chamado`;
export const STATUS_LIST = `${API_URL}/chamado/status/all`;
export const ATTENDANCE_GET_COMMENTS = `${API_URL}/chamado/GetPageComentario`;
export const ATTENDANCE_POST_COMMENT = `${API_URL}/chamado/comentario`;
export const ATTENDANCE_GET_MENSAGENS_NAO_LIDAS = `${API_URL}/chamado/GetMsgNaoLidaCount`;
export const ATTENDANCE_PUT = `${API_URL}/Chamado`;
export const ADD_ATTENDANCE_TAG = `${API_URL}/Chamado/AdicionarTag`;
export const REMOVE_ATTENDANCE_TAG = `${API_URL}/Chamado/RemoverTag`;

// Budget
export const BUDGET_PAGE = `${API_URL}/orcamento/OrcamentosChamado`;
export const BUDGET_EMAIL = `${API_URL}/Orcamento/ConfirmOrcamento`;
export const REQUEST_BUDGET = `${API_URL}/Orcamento/RequestBudget`;
export const CREATE_BUDGET = `${API_URL}/orcamento/salvarOrcamento`;
export const BUDGET = `${API_URL}/orcamento`;
export const GET_ALL_BUDGETS = `${API_URL}/Orcamento/GetAll`;
export const GET_ORDER_PAGE = `${API_URL}/orcamento/fecharpedido`;
export const UPDATE_ADDRESS_CLIENT = `${API_URL}/endereco/enderecoCliente`;
export const POST_ORDER_PAGE = `${API_URL}/orcamento/fecharpedido`;
export const GET_ALL_BUDGET_ORDER = `${API_URL}/Orcamento/GetAll`;
export const NEW_ORCAMENTO = `${API_URL}/Orcamento`;
export const PATCH_ORCAMENTO = `${API_URL}/Orcamento/Carrinho`;
export const PUT_ORCAMENTO = `${API_URL}/Orcamento`;
export const GET_BUDGET_QUERY = `${API_URL}/Orcamento/GetPage`;
export const POST_MIXED_BUDGET = `${API_URL}/Orcamento/MesclarOrcamentos`;
export const GET_PRODUCTION_ORDER = `${API_URL}/Orcamento/FichaProducao`;
export const GET_PRODUCTION_ORDER_V2 = `${MANI_API_URL}/integracao/ficha`;

// Dashboard
export const DASH_GET = `${API_URL}/Dashboard`;
export const DASH_ORDERS_COUNT = `${API_URL}/Dashboard/GetContagemPedidosStatus`;
export const DASH_ORDERS_FUNEL = `${API_URL}/Dashboard/GetFunilPedidos`;

// Pedido
export const GET_PEDIDOS = `${API_URL}/Pedido/GetAll`;
export const GET_PEDIDO_PRESCRIPTION_REPORT = `${MANI_API_URL}/integracao/dadosficha`;
export const GET_PEDIDO_DETAILS = `${API_URL}/Pedido`;
export const GET_PEDIDOS_POR_USUARIO = `${API_URL}/Pedido/GetPageFromCustomer`;
export const GET_PEDIDO_DETAILS_INFINITY = `${API_URL}/Pedido/GetPage`;
export const GET_ALL_PEDIDO_STATUS = `${API_URL}/Pedido/status`;
export const PUT_PEDIDO_STATUS = `${API_URL}/Pedido/status`;
export const POST_PEDIDO = `${API_URL}/Pedido`;
export const PEDIDO_GET_COMMENTS = `${API_URL}/Pedido/GetPageComentario`;
export const PEDIDO_POST_COMMENT = `${API_URL}/Pedido/comentario`;
export const PEDIDO_REMOVE_CLIENT = `${API_URL}/Pedido/RemoverCliente`;
export const PEDIDO_POST_USER = `${API_URL}/Pedido/responsavel`;
export const PEDIDO_PUT_VIEWLINK = `${API_URL}/Pedido/LinkVisualizado`;
export const PUT_PEDIDO = `${API_URL}/Pedido`;
export const GET_COTACAO_STATUS = `${API_URL}/Pedido/GetStatusCotacoes`;
export const GET_PEDIDO_STATUS = `${API_URL}/Pedido/GetStatusPedidos`;
export const GET_PEDIDO_ENTREGA_STATUS = `${API_URL}/Pedido/GetStatusEntrega`;
export const GET_PEDIDO_RENEGOCIACAO_STATUS = `${API_URL}/Pedido/GetStatusRenegociacao`;
export const GET_PEDIDO_PRODUCAO_STATUS = `${API_URL}/Pedido/GetStatusProducao`;
export const POST_RECUSAR_PEDIDO = `${API_URL}/Pedido/RecusarPedido`;
export const POST_EVALUATE_ORDER = `${API_URL}/Pedido/Avaliacao`;
export const PUT_GET_OUT_CHECKOUT = `${API_URL}/Pedido/CheckoutAbandonado`;
export const POST_EDITED_MESSAGE = `${API_URL}/Pedido/EnviarOfertaEditada`;
export const PUT_GET_OUT_PRECHECKOUT = `${API_URL}/Pedido/PreCheckoutAbandonado`;
export const PEDIDO_PUT_PRE_CHECKOUT_VIEWLINK = `${API_URL}/Pedido/PreCheckoutVisualizado`;
export const GET_ORDER_MESSAGE = `${API_URL}/Pedido/GetMensagem`;
export const GET_INPUT_CHANNEL = `${API_URL}/Pedido/GetAllCanal`;
export const ADD_ORDER_TAG = `${API_URL}/Pedido/AdicionarTag`;
export const REMOVE_ORDER_TAG = `${API_URL}/Pedido/RemoverTag`;
export const GET_MANI_ORDER = `${API_URL}/Pedido/GetCotacoes`;

// export const STATUS_LIST_PEDIDO = `${API_URL}/pedido/status/all`;
export const GET_MOTIVOS_CANCELAMENTO = `${API_URL}/Pedido/motivosCancelamento`;

// Pharmacy
export const GET_PHARM = `${API_URL}/Farmacia`;
export const GET_PHARM_ALL = `${API_URL}/Farmacia/GetAll`;
export const GET_PHARM_ADDRESS_ALL = `${API_URL}/Farmacia/GetFiliaisEndereco`;
export const GET_PHARM_COMFIG = `${API_URL}/Farmacia/Config`;
export const POST_PHARM_COMFIG = `${API_URL}/Farmacia/Config`;
export const GET_BANK_LIST = `${API_URL}/Farmacia/Bancos`;
export const POST_PHARM_LOGO = `${API_URL}/Farmacia/SalvarImagemLogo`;
export const GET_PROFILE_LIST = `${API_URL}/Farmacia/GetPerfis`;
export const GET_ACTIVE_MODULES = `${API_URL}/Farmacia/ModulosAtivos`;
export const GET_MANI_PHARMS = `${API_URL}/Farmacia/FarmaciaMani`;

// Payment moip
export const PAYMENT_CHECKOUT = `${API_URL}/Cobranca`;
export const PAYMENT_METHODS = `${API_URL}/Cobranca/GetAllFormasPagamento`;
export const PUT_RECEIPT_OF_TRANSFER = `${API_URL}/Cobranca/SalvarComprovante`;
export const PAYMENT_PARCELAS = `${API_URL}/Cobranca/ParcelasPorValor`;
export const PAYMENT_POST_STATUS = `${API_URL}/Cobranca/AtualizarStatus`;
export const PAYMENT_CHARGEBACK = `${API_URL}/Cobranca/CobrancasContestadasCount`;
export const PAYMENT_SET_VIEW_CHARGEBACK = `${API_URL}/Cobranca/MarcarContestadoVerificado`;

// Profile
export const USER_PROFILE = `${API_URL}/perfil`;
export const PUT_USER_PROFILE = `${API_URL}/perfil`;

// User
export const GET_PAGE_USERS = `${API_URL}/usuario/GetPage`;
export const FETCH_USERS = `${API_URL}/usuario/all/vm`;
export const CREATE_AND_UPDATE_USER = `${API_URL}/usuario`;
export const GET_USER = `${API_URL}/usuario`;
export const DELETE_USER = `${API_URL}/usuario`;

// Client
export const UPDATE_CLIENT = `${API_URL}/cliente`;
export const GET_ALL_CLIENTS = `${API_URL}/Cliente/GetAll`;
export const GET_PAGE_CLIENTS = `${API_URL}/Cliente/GetPage`;
export const CLIENT_GET_COMMENTS = `${API_URL}/Cliente/GetPageComentario`;
export const CLIENT_POST_COMMENT = `${API_URL}/Cliente/comentario`;
export const CLIENT_GET_BY_CPF = `${API_URL}/Cliente/GetClienteByCpf`;
export const TRANSFER_ORDER_CLIENT = `${API_URL}/Cliente/TransferirCliente`;
export const CLIENT_PUT_CHECKOUT = `${API_URL}/Cliente/Checkout`;

// Finances
export const GET_BALANCE = `${API_URL}/Cobranca/GetBalance`;
export const GET_FINANCES_LIST = `${API_URL}/Cobranca/GetPage`;
export const GET_FINANCES_STATUS_LIST = `${API_URL}/Cobranca/GetAllStatus`;
export const POST_FINANCES_REFOUND = `${API_URL}/Cobranca/EstornarPagamento`;
export const GET_PHARM_WIRECARD_BALANCE = `${API_URL}/Financeiro/ConsultarSaldoWirecard`;
export const GET_PHARM_BALANCE = `${API_URL}/Financeiro/SaqueLiberado`;
export const POST_PHARM_BALANCE = `${API_URL}/Financeiro/ResgatarSaldoWirecard`;
export const POST_PARTIAL_PHARM_BALANCE = `${API_URL}/Financeiro/ResgatarSaldoParcialWirecard`;
export const GET_BANK_ACCOUNTS = `${API_URL}/Financeiro/ContasBancarias`;
export const POST_BANK_ACCOUNTS = `${API_URL}/Financeiro/ContasBancarias`;
export const GET_BANK_STATEMENT = `${API_URL}/Financeiro/ConsultarExtratoWirecard`;
export const GET_BANK_STATEMENT_TAGS = `${API_URL}/Financeiro/TagsExtratoWirecard`;
export const GET_BANK_PROVIDERS = `${API_URL}/Financeiro/GetAllProvedoresBrasPag`;
export const GET_ANTICIPATION_SIMULATION = `${API_URL}/Financeiro/EstimativaAntecipacao`;
export const POST_ANTICIPATION = `${API_URL}/Financeiro/AntecipacaoReal`;
export const GET_CANCELED_HISTORY = `${API_URL}/Cobranca/HistoricoCancelamento`;

// Whatsapp
export const ENVIAR_LINK_OFERTA = `${API_URL}/Pedido/EnviarLinkOferta`;
export const POST_WHATSAPP_MESSAGE = `${API_URL}/Chat`;
export const GET_WHATSAPP_MESSAGES_PAGE = `${API_URL}/Chat/GetPage`;
export const GET_UPDATE_WHATSAPP_MESSAGE = `${API_URL}/Chat/AtualizarMensagens`;
export const GET_WHATSAPP_IMG_LIST = `${API_URL}/Chat/GetMensagensUrl`;
export const GET_WHASAPP_MODULE_STATUS = `${API_URL}/Chat/IntegracaoWhatsAppAtiva`;
export const PUT_WHATSAPP_MSG_VISUALIZADA = `${API_URL}/Chat/MarcarMsgVizualizada`;
export const PUT_WHATSAPP_ARRAY_MSG_VISUALIZADA = `${API_URL}/Chat/MarcarMsgVisualizada`;
export const POST_ZENDESK_CONTACT = `${API_URL}/Chat/EnviarEmailZendesk`;

// Preset message
export const WHASAPP_MENSAGE_PRESET_GET = `${API_URL}/MensagemRapida/GetAll`;
export const WHASAPP_MENSAGE_PRESET = `${API_URL}/MensagemRapida`;

// Integracao
export const GET_INT_FORMULACERTA = `${API_URL}/Integracao`;
export const GET_INT_MANIPULAE = `${API_URL}/Integracao`;
export const GET_INT_STATUS = `${API_URL}/Config/Integracao`;
export const POST_UPLOADFILE = `${API_URL}/Integracao/FileUpload`;
export const POST_BULKUPLOADFILE = `${API_URL}/Integracao/BulkFileUpload`;
export const POST_EMBED_FORM = `${API_URL}/Integracao/FormularioEmbedded`;
export const GET_NOTIFICATIONS = `${API_URL}/Integracao/GetNotificacoes`;
export const POST_UPDATEORDERS = `${API_URL}/Integracao/AtualizarPedidos`;
export const GET_MERCHANTID = `${API_URL}/Farmacia/GetBrasPagMerchantId`;

// Email
export const EMAIL_CONFIG = `${API_URL}/ConfiguracaoEmail`;
export const EMAIL_SMTP_TEST = `${API_URL}/ConfiguracaoEmail/TesteSmtp`;
export const EMAIL_POP_TEST = `${API_URL}/ConfiguracaoEmail/TestePop`;

// Prescricao
export const GET_RECIPES_ALL = `${API_URL}/Prescricao/GetAll`;
export const RECIPE = `${API_URL}/Prescricao`;
export const RECIPE_IMAGE = `${API_URL}/Prescricao/SalvarImagem`;

// Produto
export const PRODUCT = `${API_URL}/Produto`;
export const PRODUCT_SUGGESTION = `${API_URL}/Produto/ProdutosSugeridos`;
export const GET_PAGE_PRODUCT = `${API_URL}/Produto/GetPage`;
export const PUT_QTD_PRODUCT = `${API_URL}/Produto/AlterarQtde`;

// Entrega
export const GET_ENTREGA = `${API_URL}/Entrega`;
export const POST_ENTREGA = `${API_URL}/Entrega`;
export const PUT_ENTREGA = `${API_URL}/Entrega`;
export const DELETE_ENTREGA = `${API_URL}/Entrega`;
export const GET_ENTREGA_STATUS = `${API_URL}/Entrega/GetAllStatus`;
export const GET_FORMAS_PAGAMENTO = `${API_URL}/Entrega/GetAllFormasPagamento`;

// DashBoard
export const GET_FUNIL_PEDIDOS = `${API_URL}/Dashboard/GetFunilPedidos`;
export const GET_CONTAGEM_PEDIDOS = `${API_URL}/Dashboard/GetContagemPedidosStatus`;

// LandingPage
export const POST_NEW_PHARMACY = `${API_URL}/Integracao/LandingPage`;

// Tags
export const TAG = `${API_URL}/Tag`;
export const TAG_GETALL = `${API_URL}/Tag/GetAll`;

// Campaign
export const CAMPING = `${API_URL}/Campanha`;
export const GET_ALL_CAMPING = `${API_URL}/Campanha/GetAll`;
export const CLIENT_ACCESS_CAMPING = `${API_URL}/Campanha/SubirContagemAcesso`;

// Relatorio
export const RELATORIO = `${API_URL}/Relatorio`;

// Cupom
export const CUPOM = `${API_URL}/Cupom`;

// Campanha Covid-19
export const PRODUCT_CAMPAIGN = `${API_URL}/CampanhaProduto`;
export const CAMPAIGN_DELIVERY_VALUE = `${API_URL}/Endereco/ValorFreteCampanha`;

// ChatBot
export const CHAT_BOT_TOKEN = `${MANI_API_URL}/Usuario/BotToken`;
export const CHAT_BOT_MSG_READ = `${MANI_API_URL}/Bot/Chat`;
export const CHAT = `${MANI_API_URL}/Chat`;
export const CHAT_UPLOAD = `${MANI_API_URL}/Chat/Upload`;

// Invoices
export const INVOICES = `${GATEWAY}/invoices/v1/invoicer`;

// Shipping
export const SHIPPING = `${GATEWAY}/shipping/v1/shipment`;
