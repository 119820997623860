const initialState = {
  product: {
    id: null,
    titulo: '',
    descricao: '',
    indisponivel: false,
    controlado: false,
    qtdEstoque: 0,
    valorCompra: 0,
    valorVenda: 0,
  },
  loadProductDetails: false,
  loadProductAction: false,
  loadProductList: false,
  productActionError: false,
  list: [],
  hasMore: true,
  productsToAdd: [],
  // Suggestion products
  suggestionList: [],
  loadSuggestionList: false,
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case 'PRODUCT_DETAILS_LOADING':
      return {
        ...state,
        loadProductDetails: true,
      };

    case 'PRODUCT_DETAILS_SUCCESS':
      return {
        ...state,
        product: {
          ...action.payload,
        },
        loadProductDetails: false,
      };

    case 'PRODUCT_DETAILS_ERROR':
      return {
        ...state,
        loadProductDetails: false,
      };

    case 'PRODUCT_CLEAR':
      return {
        ...state,
        product: initialState.product,
      };

    case 'PRODUCT_ACTION_LOADING':
      return {
        ...state,
        loadProductAction: true,
        productActionError: false,
      };

    case 'PRODUCT_ACTION_SUCCESS':
      return {
        ...state,
        loadProductAction: false,
      };

    case 'PRODUCT_ACTION_ERROR':
      return {
        ...state,
        loadProductAction: false,
        productActionError: true,
      };

    case 'PRODUCT_GET_PAGE_LOADING':
      return {
        ...state,
        loadProductList: true,
      };

    case 'PRODUCT_GET_PAGE_SUCCESS': {
      let newList = state.list;
      let hasMore = true;

      if (action.payload.length <= 0) hasMore = false;

      newList = newList.concat(action.payload);

      return {
        ...state,
        loadProductList: false,
        hasMore,
        list: newList,
      };
    }

    case 'PRODUCT_GET_REFRESH_PAGE_SUCCESS':
      return {
        ...state,
        list: action.payload,
      };
    case 'PRODUCT_GET_PAGE_ERROR':
      return {
        ...state,
        loadProductList: false,
      };

    case 'PRODUCT_CLEAR_LIST':
      return {
        ...state,
        list: initialState.list,
        product: initialState.product,
        hasMore: true,
      };

    case 'PRODUCT_SET_PRODUCT_ADD_LIST':
      return {
        ...state,
        productsToAdd: action.payload,
      };

    case 'PRODUCT_CLEAR_PRODUCT_LIST':
      return {
        ...state,
        productsToAdd: [],
      };

    case 'PRODUCT_SUGGESTION_LOADING':
      return {
        ...state,
        loadSuggestionList: true,
      };

    case 'PRODUCT_SUGGESTION_SUCCESS':
      return {
        ...state,
        suggestionList: action.payload,
        loadSuggestionList: false,
      };

    case 'PRODUCT_SUGGESTION_ERROR':
      return {
        ...state,
        loadSuggestionList: false,
      };

    case 'PRODUCT_EXCLUDE_IMAGE':
      return {
        ...state,
        product: {
          ...state.product,
          imagemUrl: null,
        },
      };

    default:
      return state;
  }
};

export default product;
