const initialState = {
    pharmacy: {
        id: null,
        nome: '',
        cnpj: '',
        telefone: '',
        endereco: {
          nome: 'Endereço da Farmácia',
          bairro: '',
          cep: '',
          cidade: '',
          clienteId: null,
          complemento: '',
          estado: '',
          id: null,
          latitude: null,
          logradouro: '',
          longitude: null,
          modificado: null,
          numero: '',
          pedidoId: null,
          titulo: ''
        }
    },
    refreshList: false,
    createdNewPharmacy: false,
    loadingAction: false,
    // pharmacy List
    getListLoading: false,
    list: [],
    // pharmacy Details
    getPharmacyDetailsLoading: false,
    tabs: [],
    clearTab: false,
    nextTab: false,
    err: null,
    pharmacyUpdated: false,
    bankList: [],
    profileList: [],
    maniPharmaciesList: null,
    zendeskFormSuccess: null,
    loadingZendesk: false,
  };
  
  const pharmacy = (state = initialState, action) => {
    switch (action.type) {
      // Pharmacy list
      case "GET_PHARMACY_LIST_LOADING":
        return {
          ...state,
          getListLoading: action.payload
        };
  
      case "CLEAR_PHARMACY_LIST":
        return {
          ...state,
          list: []
        };
  
      case "GET_PHARMACY_LIST_SUCCESS":
        return {
          ...state,
          getListLoading: false,
          list: action.payload.list,
          refreshList: false
        };
  
      case "GET_PHARMACY_LIST_ERROR":
        return {
          ...state,
          getListLoading: false,
          refreshList: false,
          list: [],
          err: "Ocorreu um erro ao executar a ação."
        };
        
      // Pharmacy details
      case "GET_PHARMACY_DETAILS_LOADING":
        return {
          ...state,
          getPharmacyDetailsLoading: action.payload.loading
        };
      case "GET_PHARMACY_DETAILS_SUCCESS":
        let tabs = state.tabs;
        let addtab = true;
  
        // check if tab exists
        tabs.map((val) => {
          if (val.id === action.payload.id) {
            addtab = false;
          }
          return true;
        });
  
        if (addtab) {
          tabs.push({
            id: action.payload.id,
            code: action.payload.titulo
          });
        }
  
        return {
          ...state,
          getPharmacyDetailsLoading: false,
          PharmacyUpdated: false,
          pharmacy: {
            ...action.payload,
            endereco: {
              ...initialState.pharmacy.endereco,
              ...action.payload.endereco
            }
          },
          tabs: tabs
        };
      case "GET_PHARMACY_DETAILS_FAILURE":
        return {
          ...state,
          getPharmacyDetailsLoading: false,
          err: action.payload.err
        };
      case "UPDATE_PHARMACY_REQUEST":
        return {
          ...state,
          loadingAction: true
        };
      case "UPDATE_PHARMACY_SUCCESS":
        return {
          ...state,
          loadingAction: false,
          pharmacyUpdated: true,
          pharmacyFeedback: "Seus dados foram atualizados!"
        };
      case "UPDATE_PHARMACY_FAILURE":
        return {
          ...state,
          loadingAction: false,
          pharmacyFeedback: "Erro ao atualizar. Tente novamente ou contate-nos."
        };
  
        case "CLEAR_ERROR_PHARMACY":
        return {
          ...state,
          err: null
        };
  
      case "CLEAR_PHARMACY_DETAILS":
      return {
        ...state,
        pharmacy: initialState.pharmacy
      }
  
      case "REMOVE_TAB_PHARMACY":
        let tabs_old = state.tabs;
        tabs_old.splice(action.payload, 1);
  
        if (tabs_old.length <= 0) {
          return {
            ...state,
            tabs: tabs_old,
            clearTab: true
          };
        } else {
          return {
            ...state,
            tabs: tabs_old,
            nextTab: true
          };
        }
      case "CLEAR_CLEAR_TAB_PHARMACY":
        return {
          ...state,
          clearTab: false
        };
      case "CLEAR_NEXT_TAB_PHARMACY":
        return {
          ...state,
          nextTab: false
        };

      case "BANCK_LIST":
        return {
          ...state,
          bankList: action.payload.bancos
        }

      case "PROFILE_LIST":
        return {
          ...state,
          profileList: action.payload.data
        }

      case "GET_PHARMACY_ADDRESS_BY_CEP_SUCCESS":
        return {
          ...state,
          pharmacy: {
            ...state.pharmacy,
            endereco: {
              ...state.pharmacy.endereco,
              ...action.payload
            }
          }
        }

      case "POST_PHARMA_LOGO_SUCCESS":
        return {
          ...state,
          pharmacy: {
            ...state.pharmacy,
            imagemLogoUrl: action.payload
          }
        }

      case "CREATE_PHARMACY_SUCCESS":
        return {
          ...state,
          createdNewPharmacy: true
        }
      
      case "CREATE_PHARMACY_CLEAR":
        return {
          ...state,
          createdNewPharmacy: false
        }

      case "DELETE_PHARMACY_SUCCESS":
          return {
            ...state,
            refreshList: true
          }

      case "GET_MANI_PHARMACIES_SUCCESS":
        return {
          ...state,
          maniPharmaciesList: action.payload
        }

      case "PHARMA_POST_CONTAT_ZENDESK_LOADING":
        return {
          ...state,
          zendeskFormSuccess: null,
          loadingZendesk: false,
        };

      case "PHARMA_POST_CONTAT_ZENDESK_SUCCESS":
        return {
          ...state,
          zendeskFormSuccess: null,
          loadingZendesk: false,
        };

      case "PHARMA_POST_CONTAT_ZENDESK_ERROR":
        return {
          ...state,
          zendeskFormSuccess: true,
          loadingZendesk: false,
        };
  
      

      default: return {...state}
    }
  };
  
  export default pharmacy;