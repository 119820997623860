const initialState = {
  snackBar: {
    open: false,
    message: null,
  },
  menuMini: false,
  menuPanels: [],
};

const skeleton = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SNACKBAR':
      return {
        ...state,
        snackBar: {
          open: action.payload.open,
          message: action.payload.message,
        },
      };

    /**
     * DEPREACTED in 09/09/2019 now use CHANGE_MENU
     */
    case 'TOOGLE_MENU':
      return {
        ...state,
        menuMini: !state.menuMini,
      };

    case 'CHANGE_MENU':
      return {
        ...state,
        menuMini: action.payload,
      };

    case 'GET_MENU_ITEMS_PANELS':
      return {
        ...state,
        menuPanels: action.payload,
      };

    default: return { ...state };
  }
};

export default skeleton;
