const initialState = {
  dashboardLoading: false,
  dashboardData: {},
  myAttendancesList: [],
  abandonedOrdersList: [],
  userList: [],
};

const dash = (state = initialState, action) => {
  switch (action.type) {
    // Dash
    case 'DASH_GET_DATA_LOADING':
      return {
        ...state,
        dashboardLoading: true,
      };
    case 'DASH_GET_DATA_SUCCESS':
      return {
        ...state,
        dashboardLoading: false,
        dashboardData: action.payload,
      };
    case 'DASH_REFRESH_DATA_SUCCESS':
      return {
        ...state,
        dashboardData: action.payload,
      };
    case 'DASH_GET_DATA_ERROR':
      return {
        ...state,
        dashboardLoading: true,
        dashboardData: {},
      };
    case 'DASH_CLEAR_DATA':
      return {
        ...state,
        dashboardData: {},
      };

    // Panel My attendances
    case 'DASH_MY_ATTENDANCE_LIST_SUCCESS':
      return {
        ...state,
        myAttendancesList: action.payload,
      };
    case 'DASH_CLEAR_MY_ATTENDANCE_LIST':
      return {
        ...state,
        myAttendancesList: [],
      };

    // Panel Abandoned Orders
    case 'DASH_GET_ABANDONED_ORDER_SUCCESS':
      return {
        ...state,
        abandonedOrdersList: action.payload,
      };
    case 'DASH_CLEAR_ABANDONED_ORDER_SUCCESS':
      return {
        ...state,
        abandonedOrdersList: [],
      };
    case 'DASH_GET_USER_LIST':
      return {
        ...state,
        userList: action.payload.list,
      };
    case 'DASH_CLEAR_USER_LIST':
      return {
        ...state,
        userList: [],
      };
    default: {
      return {
        ...state,
      };
    }
  }
};

export default dash;
