import "regenerator-runtime/runtime";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

// REDUCERS
import operations from "../operations/redux/operationsReducer";
import logon from "../logon/redux/logonReducer";
import client from "../client/redux/clientReducer";
import address from "../address/redux/addressReducer";
import attendance from "../attendance/redux/AttendanceReducer";
import order from "../order/redux/orderReducer";
import skeleton from "../skeleton/redux/skeletonReducer";
import budget from "../budget/redux/budgetReducer";
import checkout from "../checkout/redux/checkoutReducer";
import finance from "../finances/redux/FinanacesReducer";
import whatsapp from "../whatsapp/redux/whatsappReducer";
import config from "../config/redux/configReducer";
import pharmacy from "../pharmacies/redux/pharmacyReducer";
import recipes from "../recipes/redux/recipesReducer";
import user from "../user/redux/userReducer";
import product from "../product/redux/productReducer";
import delivery from "../delivery/redux/deliveryReducer";
import dash from "../dash/redux/dashReducer";
import landingPage from "../landingPage/redux/landingPageReducer";
import preCheckout from "../PreCheckout/redux/PreCheckoutReducer";
import covid from "../CovidNao/redux/covidReducer";
import { IS_PROD } from "../../constants/apiBase";

const Store = IS_PROD
  ? createStore(
      combineReducers({
        operations,
        logon,
        dash,
        client,
        address,
        attendance,
        order,
        budget,
        skeleton,
        checkout,
        finance,
        whatsapp,
        config,
        pharmacy,
        recipes,
        user,
        product,
        delivery,
        landingPage,
        preCheckout,
        covid,
      }),
      applyMiddleware(thunk)
    )
  : createStore(
      combineReducers({
        operations,
        logon,
        dash,
        client,
        address,
        attendance,
        order,
        budget,
        skeleton,
        checkout,
        finance,
        whatsapp,
        config,
        pharmacy,
        recipes,
        user,
        product,
        delivery,
        landingPage,
        preCheckout,
        covid,
      }),
      applyMiddleware(thunk, logger)
    );

window.store = Store;

export default Store;
