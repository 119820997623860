const initialState = {
  user: {
    id: null,
    login: null,
    senha: null,
    perfilId: null,
    farmaciaId: null,
    automacaoAtiva: false,
    admin: false,
    perfil: {
      id: null,
      nome: null,
      email: null,
      cpf: null,
      telefone: null,
      whatsapp: null,
      fotoId: null,
      fotoUrl: null,
      dataNascimento: null,
    },
  },
  loadingAction: false,
  // user List
  getListLoading: false,
  list: [],
  hasMore: true,
  // client Details
  getUserDetailsLoading: false,
  tabs: [],
  clearTab: false,
  nextTab: false,
  err: null,
  userUpdated: false,
  userCreated: false,
  userFeedback: null,
  loadingAutomationStatus: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_LIST_LOADING':
      return {
        ...state,
        getListLoading: action.payload,
      };

    case 'CLEAR_USER_LIST':
      return {
        ...state,
        list: [],
        hasMore: true,
      };

    case 'GET_USER_PARTIAL_LIST_SUCCESS':
      let new_list = state.list;
      let hasMore = true;

      if (action.payload.list.length <= 0) hasMore = false;

      action.payload.list.map((item) => {
        new_list.push(item);
        return true;
      });

      return {
        ...state,
        getListLoading: false,
        list: new_list,
        hasMore,
      };

    case 'GET_USER_LIST_ERROR':
      return {
        ...state,
        getListLoading: false,
        list: [],
        error: 'Ocorreu um erro ao executar a ação.',
      };

    case 'GET_USER_INTERVAL_SUCCESS':
      return {
        ...state,
        list: action.payload.list,
      };

    case 'GET_USER_DETAILS_LOADING':
      return {
        ...state,
        getUserDetailsLoading: action.payload.loading,
        userUpdated: false,
      };
    case 'GET_USER_DETAILS_SUCCESS':
      let tabs = state.tabs;
      let addtab = true;

      // check if tab exists
      tabs.map((val) => {
        if (val.id === action.payload.id) {
          addtab = false;
        }
        return true;
      });

      if (addtab) {
        tabs.push({
          id: action.payload.id,
          code: action.payload.login,
        });
      }

      return {
        ...state,
        getUserDetailsLoading: false,
        user: {
          ...action.payload,
        },
        tabs,
      };

    case 'GET_USER_DETAILS_FAILURE':
      return {
        ...state,
        getUserDetailsLoading: false,
        err: action.payload.err,
      };

    case 'UPDATE_USER_REQUEST':
      return {
        ...state,
        loadingAction: true,
      };

    case 'POST_USER_SUCCESS':
      return {
        ...state,
        loadingAction: false,
        userCreated: true,
      };

    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        loadingAction: false,
        userUpdated: true,
        userFeedback: 'Seus dados foram atualizados!',
      };

    case 'UPDATE_USER_FAILURE':
      return {
        ...state,
        loadingAction: false,
        userFeedback: action.payload,
      };

    case 'CLEAR_ERROR_USER':
      return {
        ...state,
        err: null,
      };

    case 'CLEAR_USER_DETAILS':
      return {
        ...state,
        user: initialState.user,
        getUserDetailsLoading: false,
        clearTab: false,
        nextTab: false,
        err: null,
        userUpdated: false,
        userCreated: false,
        userFeedback: null,
      };

    case 'REMOVE_TAB_USER':
      let tabs_old = state.tabs;
      tabs_old.splice(action.payload, 1);

      if (tabs_old.length <= 0) {
        return {
          ...state,
          tabs: tabs_old,
          clearTab: true,
        };
      }

      return {
        ...state,
        tabs: tabs_old,
        nextTab: true,
      };

    case 'CLEAR_CLEAR_TAB_USER':
      return {
        ...state,
        clearTab: false,
      };
    case 'CLEAR_NEXT_TAB_USER':
      return {
        ...state,
        nextTab: false,
      };

    case 'DELETE_USER_LOADING':
      return {
        ...state,
        loadingAction: true,
      };
    case 'DELETE_USER_SUCCESS':
      return {
        ...state,
        loadingAction: false,
      };
    case 'DELETE_USER_ERROR':
      return {
        ...state,
        loadingAction: false,
      };

    case 'USER_UPDATE_ACTIVESTATUS_LOADING':
      return {
        ...state,
        loadingAutomationStatus: true,
      };

    case 'USER_UPDATE_ACTIVESTATUS_SUCCESS':
      return {
        ...state,
        loadingAutomationStatus: false,
      };

    case 'USER_UPDATE_ACTIVESTATUS_ERROR':
      return {
        ...state,
        loadingAutomationStatus: false,
      };

    default:
      return state;
  }
};

export default user;
