const initialState = {
  messageList: [],
  imageList: [],
  hasMoreMessage: true,
  moduleStatus: false,
  loadMensages: false,
  presetMensages: [],
};

const whatsapp = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_WHATSAPP_MENSSAGE':
      return {
        ...state,
        loadMensages: true,
      };

    case 'CHAT_SEND_MESSAGE_SUCCESS': {
      let newMessageList = state.messageList;

      newMessageList = newMessageList.concat(action.payload);

      return {
        ...state,
        messageList: newMessageList,
      };
    }

    case 'GET_WHATSAPP_MENSSAGE_SUCCESS':
      let newMessageList = state.messageList.reverse();
      let hasMoreMessage = true;

      if (action.payload.data.length <= 0)
        hasMoreMessage = false;

      newMessageList = newMessageList.concat(action.payload.data);

      return {
        ...state,
        messageList: newMessageList.reverse(),
        loadMensages: false,
        hasMoreMessage,
      };

    case 'GET_WHATSAPP_MENSSAGE_ERROR':
      return {
        ...state,
        loadMensages: false,
        hasMoreMessage: false,
      };

    case 'GET_UPDATE_MESSAGE':
      return {
        ...state,
        messageList: action.payload.data.reverse()
      }

    case 'CLEAR_MESSAGES_LIST':
      return {
        ...state,
        messageList: [],
        imageList: [],
        hasMoreMessage: true,
      };

    case 'GET_WHATSAPP_IMG_LIST':
      return {
        ...state,
        imageList: action.payload.data.reverse(),
      };

    case 'CLEAR_WHATSAPP_IMG_LIST':
      return {
        ...state,
        imageList: initialState.imageList,
      };

    case 'WHATSAPP_MODULE_STATUS':
      return {
        ...state,
        moduleStatus: action.payload,
      };

    case 'WHATSAPP_MODULE_STATUS_ERROR':
      return {
        ...state,
        moduleStatus: false,
      };

    case 'WHATSAPP_GET_PRESET_MESSAGES':
      return {
        ...state,
        presetMensages: action.payload,
      };

    case 'WHATSAPP_POST_PRESET_MESSAGES_LOADING':
      return {
        ...state,
        loadingNewPresetMensageSystem: true,
      };

    case 'WHATSAPP_POST_PRESET_MESSAGES':
      const newPresetMessages = state.presetMensages;
      newPresetMessages.push(action.payload);

      return {
        ...state,
        presetMensages: newPresetMessages,
        loadingNewPresetMensageSystem: false,
      };

    case 'WHATSAPP_POST_PRESET_MESSAGES_ERROR':
      return {
        ...state,
        loadingNewPresetMensageSystem: false,
      };

    case 'WHATSAPP_DELETE_PRESET_MESSAGES': {
      let resumedPresetMessages = [];

      if (state.presetMensages) {
        state.presetMensages.map((item) => {
          if (item.id !== action.payload) {
            resumedPresetMessages.push(item);
          }
          return true;
        });
      }

      return {
        ...state,
        presetMensages: resumedPresetMessages,
      };
    }
    case 'WHATSAPP_PUT_PRESET_MESSAGES': {
      const alteredPresetMessages = [];

      if (state.presetMensages) {
        state.presetMensages.map((item) => {
          if (item.id === action.payload.messageId) {
            alteredPresetMessages.push({
              ...item,
              mensagem: action.payload.newMessage,
              titulo: action.payload.newTitle,
            });
          } else {
            alteredPresetMessages.push(item);
          }

          return true;
        });
      }

      return {
        ...state,
        presetMensages: alteredPresetMessages,
      };
    }
    default:
      return { ...state };
  }
};

export default whatsapp;
