const initialState = {
  loadingAction: false,
  client: {
    id: null,
    nome: null,
    email: null,
    cpf: null,
    telefone: null,
    whatsapp: null,
    dtNascimento: null,
    rg: null,
    orgEmissor: null,
  },
  clientFeedback: null,
  // client List
  getListLoading: false,
  list: [],
  queryOpen: false,
  queryState: null,
  query: null,
  hasMore: true,
  loadQuery: false,
  // client Details
  getClientDetailsLoading: false,
  tabs: [],
  clearTab: false,
  nextTab: false,
  err: null,
  clientUpdated: false,
  createClientLoading: false,
  createClienterror: false,
  // comments
  comments: null,
  postCommentLoading: false,
  refreshComments: false,
};

const client = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLIENT_LIST_LOADING':
      return {
        ...state,
        getListLoading: action.payload,
      };

    case 'GET_CLIENT_LIST_SUCCESS':
      return {
        ...state,
        getListLoading: false,
        list: action.payload.list.data,
      };

    case 'CLEAR_CLIENT_LIST':
      return {
        ...state,
        list: [],
        hasMore: true,
      };

    case 'GET_CLIENT_PARTIAL_LIST_SUCCESS': {
      const newList = state.list;
      let hasMore = true;

      if (action.payload.list.data.length <= 0) hasMore = false;

      action.payload.list.data.map((item) => {
        newList.push(item);
        return true;
      });

      return {
        ...state,
        getListLoading: false,
        list: newList,
        hasMore,
      };
    }

    case 'GET_CLIENT_LIST_ERROR':
      return {
        ...state,
        getListLoading: false,
        list: [],
        error: 'Ocorreu um erro ao executar a ação.',
      };

    case 'GET_CLIENT_INTERVAL_SUCCESS':
      return {
        ...state,
        list: action.payload.list.data,
      };

    case 'GET_CLIENT_LIST_QUERY_LOADING':
      return {
        ...state,
        hasMore: false,
        loadQuery: true,
      };

    case 'GET_CLIENT_LIST_QUERY_SUCCESS':
      return {
        ...state,
        list: action.payload.list.data,
        hasMore: false,
        loadQuery: false,
      };

    case 'GET_CLIENT_LIST_QUERY_ERROR':
      return {
        ...state,
        list: action.payload.list.data,
        hasMore: false,
        loadQuery: false,
      };

    case 'GET_CLIENT_DETAILS_LOADING':
      return {
        ...state,
        getClientDetailsLoading: action.payload.loading,
      };

    case 'GET_CLIENT_DETAILS_SUCCESS': {
      const { tabs } = state;
      let addtab = true;

      tabs.map((val) => {
        if (val.id === action.payload.id) {
          addtab = false;
        }
        return true;
      });

      if (addtab) {
        tabs.push({
          id: action.payload.id,
          code: action.payload.nome
                || action.payload.whatsapp
                || action.payload.telefone
                || action.payload.email
                || action.payload.cpf,
        });
      }

      return {
        ...state,
        getClientDetailsLoading: false,
        clientUpdated: false,
        client: {
          ...action.payload,
        },
        tabs,
      };
    }

    case 'GET_CLIENT_DETAILS_FAILURE':
      return {
        ...state,
        getClientDetailsLoading: false,
        err: action.payload.err,
      };

    case 'GET_CLIENT_DETAILS_WITHOUT_TABS_SUCCESS':
      return {
        ...state,
        getClientDetailsLoading: false,
        clientUpdated: false,
        client: {
          ...action.payload,
        },
      };

    case 'UPDATE_CLIENT_REQUEST':
      return {
        ...state,
        loadingAction: true,
      };

    case 'UPDATE_CLIENT_SUCCESS':
      return {
        ...state,
        loadingAction: false,
        clientUpdated: true,
        clientFeedback: 'Seus dados foram atualizados!',
      };
    case 'UPDATE_CLIENT_FAILURE':
      return {
        ...state,
        loadingAction: false,
        clientFeedback: action.payload,
      };

    case 'GET_CLIENT_REQUEST':
      return {
        ...state,
        isFething: true,
        hasError: false,
      };

    case 'GET_CLIENT_SUCCESS':
      return {
        ...state,
        isFething: false,
        hasError: false,
        client: action.payload,
      };

    case 'SET_LOADING_FAILURE':
      return {
        ...state,
        isFething: false,
        hasError: true,
        err: action.payload,
      };

    case 'CLIENT_CLEAR_FEEDBACK':
      return {
        ...state,
        clientFeedback: null,
      };

    case 'CLEAR_ERROR_CLIENT':
      return {
        ...state,
        err: null,
      };

    case 'CLEAR_CLIENT_DETAILS':
      return {
        ...state,
        client: initialState.client,
      };

    case 'CLEAR_ALL_CLIENT_DETAILS':
      return {
        ...state,
        client: initialState.client,
        tabs: [],
      };

    case 'REMOVE_TAB_CLIENT': {
      const tabsOld = state.tabs;
      tabsOld.splice(action.payload, 1);

      if (tabsOld.length <= 0) {
        return {
          ...state,
          tabs: tabsOld,
          clearTab: true,
        };
      }

      return {
        ...state,
        tabs: tabsOld,
        nextTab: true,
      };
    }

    case 'CLEAR_CLEAR_TAB_CLIENT':
      return {
        ...state,
        clearTab: false,
      };

    case 'CLEAR_NEXT_TAB_CLIENT':
      return {
        ...state,
        nextTab: false,
      };

    case 'CLIENT_COMMENTS_SUCCESS':
      return {
        ...state,
        comments: action.payload,
        refreshComments: false,
      };

    case 'CLIENT_COMMENTS_ERROR':
      return {
        ...state,
        comments: action.payload,
        refreshComments: false,
      };

    case 'CLIENT_POST_COMMENT_LOADING':
      return {
        ...state,
        postCommentLoading: true,
      };

    case 'CLIENT_POST_COMMENT_SUCCESS':
      return {
        ...state,
        postCommentLoading: false,
        refreshComments: true,
      };

    case 'CLIENT_POST_COMMENT_ERROR':
      return {
        ...state,
        postCommentLoading: false,
      };

    case 'CREATE_CLIENT_SUCCESS':
      return {
        ...state,
        clientUpdated: true,
        client: {
          ...state.client,
          ...action.payload,
        },
      };

    case 'CLIENT_SET_QUERY_STATUS':
      return {
        ...state,
        queryOpen: action.payload.status,
        hasMore: !action.payload.status,
        queryState: action.payload.queryState,
        query: action.payload.query,
      };

    default:
      return { ...state };
  }
};

export default client;
