const initialState = {
  list: [],
  loadingList: false,
  hasMore: true,
  listQuery: [],
  loadingQuery: false,
  loadingBudget: false,
  budgetsToMix: [],
  budgetMixedId: null,
  mixBudgetLoading: false,
};

const budget = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BUDGET_LOADING':
      return {
        ...state,
        loadingList: action.payload,
      };

    case 'GET_BUDGETS_SUCCESS':
      return {
        ...state,
        loadingList: false,
        list: action.payload,
      };

    case 'BUDGET_GET_LIST_LOADING':
      return {
        ...state,
        loadingQuery: true,
      };

    case 'BUDGET_GET_LIST_SUCCESS': {
      let newList = state.listQuery;
      let hasMore = true;

      if (action.payload.length <= 0) hasMore = false;

      newList = newList.concat(action.payload);

      return {
        ...state,
        loadingQuery: false,
        hasMore,
        listQuery: newList,
      };
    }

    case 'BUDGET_GET_REFRESH_LIST_SUCCESS':
      return {
        ...state,
        listQuery: action.payload,
      };

    case 'BUDGET_GET_LIST_ERROR':
      return {
        ...state,
        loadingQuery: false,
      };

    case 'CLEAR_BUDGET_QUERY':
      return {
        ...state,
        listQuery: [],
        list: [],
        budgetsToMix: [],
        hasMore: true,
      };

    case 'BUDGET_CLEAR_LIST':
      return {
        ...state,
        listQuery: [],
        hasMore: true,
      };

    case 'INSERT_BUDGET_LOADING':
      return {
        ...state,
        loadingBudget: true,
        err: false,
      };

    case 'INSERT_BUDGET_SUCCESS':
      return {
        ...state,
        loadingBudget: false,
        err: false,
      };

    case 'INSERT_BUDGET_ERROR':
      return {
        ...state,
        loadingBudget: false,
        err: true,
      };

    case 'BUDGET_SET_MIXED_BUDGET_LIST':
      return {
        ...state,
        budgetsToMix: action.payload,
      };

    case 'BUDGET_NEW_MIX_LOADING':
      return {
        ...state,
        mixBudgetLoading: true,
      };

    case 'BUDGET_NEW_MIXED_ID_SUCCESS':
      return {
        ...state,
        budgetMixedId: action.payload,
        mixBudgetLoading: false,
      };

    case 'BUDGET_NEW_MIXED_ID_ERROR':
      return {
        ...state,
        mixBudgetLoading: false,
      };

    case 'BUDGET_CLEAR_MIXED_ID':
      return {
        ...state,
        budgetMixedId: null,
      };

    default: return { ...state };
  }
};

export default budget;
