import { useEffect } from 'react';
import { useClearCache } from "react-clear-cache";
 
const ClearLastVersionCache = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    if (!isLatestVersion) {   
      emptyCacheStorage();
    }
  }, []);

  return null;
};
 
export default ClearLastVersionCache;