import React from 'react';
import ReactDOM from 'react-dom';
import ReactPixel from 'react-facebook-pixel';
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import numeral from 'numeral';

import App from './containers/app';
import './style.css';
 
const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('732701606908722', null, options);

numeral.register("locale", "br", {
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  currency: {
    symbol: "R$"
  }
});

numeral.locale("br");

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Route component={App} />
  </Router>,
  document.getElementById('root')
);