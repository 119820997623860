const initialState = {
  type: null,
  paymentMethods: null,
  step: 0,
  detailsError: null,
  detailsLoading: false,
  details: {},
  itens: [],
  bankList: [],
  client: {},
  clientAddresses: [],
  address: {},
  addressError: '',
  pharmacyAddressList: [],
  pharmacyAddressLoading: false,
  addressUpdateSuccess: null,
  deliveryMode: false,
  pharmacyLoading: false,
  pharmacy: {
    id: null,
    endereco: {}
  },
  checkout: {
    total: 0,
    itens: []
  },
  comprovanteUrl: null,
  usaMaxiPago: false,
  checkoutLoading: false,
  checkoutSuccess: false,
  checkoutSuccessBoleto: false,
  checkoutError: false,
  linkBoleto: null,
  formaPagamento: null,
  card: {
    brand: null
  },
  hasControled: false,
  uploadReceiptUrl: null,
  uploadReceiptUrlLoading: false,
  rejectOrder: false,
  vendorMode: null,
  parcelamento: [],
  forceFreeDelivery: false,
  deliveryValueByCep: null,
  setFreeDelivey: false,
  entregaPermitida: true,
  motoboy: null,
  // evaluate
  postEvaluateLoading: false,
  postEvaluateMensage: null,
  // search Client
  searchClient: null,
  searchClientLoading: false,
  // validate Client
  validationClientLoading: false,
  validationClientError: null,
  // Product suggestions
  updateOrderLoading: false,
  // Braspag Hash
  clientIp: null,
  braspagHash: null,
  merchantId: null,
  merchantIdLoading: false,

  // Order details
  orderDetailsLoading: false,
  orderDetailsError: false,

  // Reschedule date
  rescheduleDateLoading: false,
  rescheduleDateError: false,
};

const checkout = (state = initialState, action) => {
  switch (action.type) {
    case "CHECKOUT_PAYMENT_METHODS_SUCCESS":
      return {
        ...state,
        paymentMethods: action.payload
      }
    case "SET_STEP":
      return {
        ...state,
        step: action.payload
      }
    case "SET_SETP_BACK":
      return {
        ...state,
        step: (action.payload - 1)
      }
    case "CHECKOUT_CLEAR_ERROR":
      return {
        ...state,
        checkoutError: false
      }

    case "CHECKOUT_REJECT_ORDER":
      return {
        ...state,
        details: {
          ...state.details,
          statusId: 'b5c7346f-195b-4aec-a597-175af663589b',
          status: 'Rejeitado'
        },
        rejectOrder: true,
      }

    case "CHECKOUT_ORDER_DETAILS_LOADING":
      return {
        ...state,
        detailsLoading: action.payload,
        rejectOrder: false
      };
    case "CHECKOUT_ORDER_DETAILS_SUCCESS":
      return {
        ...state,
        detailsLoading: false,
        ...action.payload,
      }
    case "CHECKOUT_ORDER_DETAILS_ERROR":
      return {
        ...state,
        detailsLoading: false,
        detailsError: action.payload
      }
    case "CHECKOUT_PHARMACY_LOADING":
      return {
        ...state,
        pharmacyLoading: action.payload,
      }
    case "CHECKOUT_PHARMACY_SUCCESS":
      return {
        ...state,
        pharmacy: action.payload
      }
    case "CHECKOUT_UPDATE_ITENS":
      return {
        ...state,
        checkout: {
          ...state.checkout,
          total: action.payload.total,
          itens: action.payload.itens
        },
        hasControled: action.payload.hasControled,
        setFreeDelivey: action.payload.setFreeDelivey,
      }
    case 'CHECKOUT_UPDATE_BUDGET':
      return {
        ...state,
        itens: action.payload,
      }

    case "SET_HASCONTROLED":
      return {
        ...state,
        hasControled: action.payload,
      }

    case "UPDATE_REDUCER_ADDRESS":
      return {
        ...state,
        address: action.payload,
      }

    case "CHECKOUT_ADDRESS_UPDATE_SUCCESS": {
      const newClientAddresses = [];
        
      if (state.clientAddresses.length > 0) {
        state.clientAddresses.forEach((item) => {
          if (item.id === action.payload.id) {
            newClientAddresses.push(action.payload);
          } else {
            newClientAddresses.push(item);
          }
        })
      }

      return {
        ...state,
        addressUpdateSuccess: true,
        address: action.payload,
        clientAddresses: newClientAddresses,
      }

    }

    case "CHECKOUT_ADD_TO_CLIENT_ADDRESS":
      return {
        ...state,
        clientAddresses: [...initialState.clientAddresses, action.payload],
      }

    case "CHECKOUT_ADDRESS_UPDATE_ERROR":
      return {
        ...state,
        addressUpdateSuccess: false,
      }

    case "CHECKOUT_CLEAR_ADDRESS_UPDATE":
      return {
        ...state,
        addressUpdateSuccess: null
      }

    case "CHECKOUT_SAVE_CLIENT":
      return {
        ...state,
        client: action.payload
      }
    case "CHECKOUT_SUCCESS":
      return {
        ...state,
        checkoutSuccess: true,
        checkoutLoading: false,
        step: 0,
        details: {
          ...state.details,
          statusId: action.payload.payment.statusId
        },
        formaPagamento: action.payload.paymentMethod
      }
    case "CHECKOUT_SUCCESS_BOLETO":
      return {
        ...state,
        checkoutSuccess: true,
        checkoutSuccessBoleto: true,
        checkoutLoading: false,
        linkBoleto: action.payload.linkBoleto,
        step: 0,
        details: {
          ...state.details,
          statusId: action.payload.statusId
        },
        formaPagamento: "BOLETO"
      }
    case "CHECKOUT_ERROR":
      return {
        ...state,
        checkoutError: action.payload,
        checkoutLoading: false,
      }
    case "CHECKOUT_PAYMENT_LOADING":
      return {
        ...state,
        checkoutLoading: true
      }
    case "CHECKOUT_BRAND_CHECK":
      return {
        ...state,
        card: {
          ...state.card,
          brand: action.payload
        }
      }

    case "CHECKOUT_RECEIPT_UPLOAD_LOADING":
      return {
        ...state,
        uploadReceiptUrlLoading: true
      }

    case "CHECKOUT_RECEIPT_UPLOAD_SUCCESS":
      return {
        ...state,
        comprovanteUrl: action.payload,
        uploadReceiptUrl: action.payload,
        uploadReceiptUrlLoading: false
      }

    case "CHECKOUT_RECEIPT_UPLOAD_ERROR":
      return {
        ...state,
        uploadReceiptUrlLoading: false
      }

    case "CHECKOUT_SET_DELIVERY_MODE":
      return {
        ...state,
        deliveryMode: action.payload
      }

    case "CHECKOUT_GET_PHARMACYS_ADDRESS_LOADING":
      return {
        ...state,
        pharmacyAddressLoading: true,

      }

    case "CHECKOUT_GET_PHARMACYS_ADDRESS_SUCCESS":
      return {
        ...state,
        pharmacyAddressList: action.payload,
        pharmacyAddressLoading: false
      }

    case "CHECKOUT_GET_PHARMACYS_ADDRESS_ERROR":
      return {
        ...state,
        pharmacyAddressLoading: false
      }

    case "CHECKOUT_SET_VENDOR_MODE":
      return {
        ...state,
        vendorMode: action.payload
      }

    case "CHECKOUT_GET_PARCELAS_CARTAO_SUCCESS":
      return {
        ...state,
        parcelamento: action.payload
      }

    case "CHECKOUT_GET_DELIVERY_VALUE_LOADING":
      return {
        ...state,
        pharmacyAddressLoading: true
      }

    case "CHECKOUT_GET_DELIVERY_VALUE_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          deliveryValue: action.payload.valor,
        },
        forceFreeDelivery: action.payload.freteGratis,
        deliveryValueByCep: action.payload.valor,
        cobreRegiao: action.payload.entregaPorMotoboy,
        entregaPermitida: action.payload.cepAtendido,
        freteGratisApartiDeValor: action.payload.freteGratisApartiDeValor,
        pharmacyAddressLoading: false,
        addressError: action.payload.msgErro,
        address: {
          ...state.address,
          cep: action.payload.cep || null
        }
      }

    case 'CHECKOUT_CLEAR_DELYVERY_VALUE':
      return {
        ...state,
        forceFreeDelivery: false,
        cobreRegiao: true,
        entregaPermitida: true,
        deliveryValueByCep: null,
      };

    case "CHECKOUT_GET_DELIVERY_VALUE_ERROR":
      return {
        ...state,
        pharmacyAddressLoading: false,
      }

    case "CHECKOUT_POST_EVALUATE_LOADING":
      return {
        ...state,
        postEvaluateLoading: true
      }

    case "CHECKOUT_POST_EVALUATE_SUCCESS":
      return {
        ...state,
        postEvaluateLoading: false,
        postEvaluateMensage: "Avaliação enviada com sucesso!"
      }

    case "CHECKOUT_POST_EVALUATE_ERROR":
      return {
        ...state,
        postEvaluateLoading: false,
        postEvaluateMensage: "Erro ao enviar a avaliação, tente novamente mais tarde."
      }

    case "CHECKOUT_CLEAR_EVALUATE_MENSAGE":
      return {
        ...state,
        postEvaluateMensage: null
      }

    case "CHECKOUT_GET_BANK_ACCOUNTS_SUCCESS":
      return {
        ...state,
        bankList: action.payload
      }

    case "CHECKOUT_GET_CLIENT_ADDRESSES_SUCCESS":
      return {
        ...state,
        clientAddresses: action.payload
      }

    // SEARCH CLIENT BY CEP

    case "CHECKOUT_GET_CLIENT_BY_CEP_LOADING":
      return {
        ...state,
        searchClientLoading: true
      }
    case "CHECKOUT_GET_CLIENT_BY_CEP_SUCCESS":
      return {
        ...state,
        searchClientLoading: false,
        searchClient: action.payload
      }
    case "CHECKOUT_GET_CLIENT_BY_CPF_ERROR":
      return {
        ...state,
        searchClientLoading: false,
        searchClient: null
      }
    case "CHECKOUT_CLEAR_CLIENT_BY_CPF":
      return {
        ...state,
        searchClient: null
      }

    // UPDATE CLIENT ORDER

    case "CHECKOUT_UPDATE_CLIENT_LOADING":
      return {
        ...state,
        validationClientLoading: true
      }
    case "CHECKOUT_UPDATE_CLIENT_SUCCESS":
      return {
        ...state,
        validationClientLoading: false,
        client: action.payload,
      }
    case 'CHECKOUT_UPDATE_CLIENT_ADDRESS_SUCCESS':
      return {
        ...state,
        validationClientLoading: false,
      }
    case "CHECKOUT_UPDATE_CLIENT_ERROR":
      return {
        ...state,
        validationClientLoading: false,
        validationClientError: action.payload
      }

    case "CHECKOUT_CLEAR_UPDATE_CLIENT":
      return {
        ...state,
        validationClientError: null,
      }

    case "PUT_STATUS_SUCCESS":
      return {
        ...state,
        details: {
          ...state.details,
          statusId: action.payload.idStatusPedido,
        }
      }

    case 'CHECKOUT_ADD_PRODUCT_LOADING':
      return {
        ...state,
        updateOrderLoading: true,
      };

    case 'CHECKOUT_ADD_PRODUCT_SUCCESS': {
      if (action.payload.newBudgetItem) {
        return {
          ...state,
          updateOrderLoading: false,
          itens: [
            ...state.itens,
            action.payload.newBudgetItem,
          ],
        };
      } else {
        return {
          ...state,
          updateOrderLoading: false,
          itens: action.payload.newBudgetList,
        };
      }
    }

    case 'CHECKOUT_SET_ORDER_ADDRESS':
      return {
        ...state,
        address: action.payload,
      }

    case 'CHECKOUT_ADD_PRODUCT_ERROR':
      return {
        ...state,
        updateOrderLoading: false,
      };

    case 'CHECKOUT_SET_BRASPAG_HASH':
      return {
        ...state,
        braspagHash: action.payload,
      };

    case 'CHECKOUT_SET_CLIENT_IP':
      return {
        ...state,
        clientIp: action.payload,
      };

    case 'CHECKOUT_GET_MERCHANTID_LOADING':
      return {
        ...state,
        merchantIdLoading: true,
      };

    case 'CHECKOUT_GET_MERCHANTID_SUCCESS':
      return {
        ...state,
        merchantIdLoading: true,
        merchantId: action.payload.brasPagMerchantId,
      };

    case 'CHECKOUT_GET_MERCHANTID_ERROR':
      return {
        ...state,
        merchantIdLoading: false,
      }

    case 'SET_LOADING_ORDER_DETAILS':
      return {
        ...state,
        orderDetailsLoading: action.payload
      }

    case 'SET_ERROR_ORDER_DETAILS':
      return {
        ...state,
        orderDetailsError: action.payload
      }

    case 'SET_LOADING_RESCHEDULE_DATE':
      return {
        ...state,
        rescheduleDateLoading: action.payload
      }

    case 'SET_ERROR_RESCHEDULE_DATE':
      return {
        ...state,
        rescheduleDateError: action.payload
      }
    
    case 'SET_UPDATE_REBUY_DATE':
      return {
        ...state,
        dataFimTratamento: action.payload,
      }

    case 'CHECKOUT_UPDATE_CLIENT_EMAIL':
      return {
        ...state,
        client: {
          ...state.client,
          email: action.payload,
        }
      }

    case 'CHECKOUT_UPDATE_REJECT_REBUY_OFFER':
      return {
        ...state,
        tratamentoEncerrado: true,
      };
    default:
      return { ...state };
  }
}

export default checkout;