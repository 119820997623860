const initialState = {
  delivery: {
    id: null,
    criado: null,
    modificado: null,
    nome: null,
    formaPagamento: null,
    statusEntrega: null,
    imagemComprovanteUrl: null,
    observacao: null,
    cobrancaId: null,
    pedidoId: null,
    farmaciaId: null,
    statusEntregaId: null,
    latitude: 0,
    longitude: 0,
    newLatitude: 0,
    newLongitude: 0,
  },
  getDeliveryLoading: false,
  getDeliveryError: false,
  postDeliveryError: false,
  updateDeliveryLoading: false,
  updateDeliveryError: false,
  deliveryStatus: [],
  paymentMethods: [],
  newDeliveryId: null,
};

const delivey = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DELIVERY_LOADING':
      return ({
        ...state,
        getDeliveryLoading: true,
        newDeliveryId: null,
      });

    case 'GET_DELIVERY_SUCCESS':
      return ({
        ...state,
        delivery: {
          ...state.delivery,
          ...action.payload.data,
        },
        getDeliveryLoading: false,
      });

    case 'GET_DELIVERY_ERROR':
      return ({
        ...state,
        delivery: initialState.delivery,
        getDeliveryError: true,
        getDeliveryLoading: false,
      });


    case 'CLEAR_GET_DELYVERY_ERROR':
      return ({
        ...state,
        getDeliveryError: false,
      });

    case 'POST_DELIVERY_LOADING':
      return {
        ...state,
        updateDeliveryLoading: true,
      };

    case 'POST_DELIVERY_SUCCESS':
      return ({
        ...state,
        newDeliveryId: action.payload.data,
        updateDeliveryLoading: false,
        postDeliveryError: false,
      });

    case 'POST_DELIVERY_ERROR':
      return ({
        ...state,
        updateDeliveryLoading: false,
        postDeliveryError: true,
      });

    case 'UPDATE_DELIVERY_LOADING':
      return ({
        ...state,
        updateDeliveryLoading: true,
      });

    case 'UPDATE_DELIVERY_SUCCESS':
      return ({
        ...state,
        updateDeliveryLoading: false,
      });

    case 'UPDATE_DELIVERY_ERROR':
      return ({
        ...state,
        updateDeliveryLoading: false,
        updateDeliveryError: true,
      });

    case 'CLEAR_UPDATE_DELYVERY_ERROR':
      return ({
        ...state,
        updateDeliveryError: false,
      });

    case 'GET_DELIVERY_STATUS_SUCCESS':
      return ({
        ...state,
        deliveryStatus: action.payload.data,
      });

    case 'GET_DELIVERY_STATUS_ERROR':
      return ({
        ...state,
        deliveryStatus: initialState.deliveryStatus,
      });

    case 'GET_DELIVERY_PAYMENT_TYPES_SUCCESS':
      return ({
        ...state,
        paymentMethods: action.payload.data,
      });

    case 'GET_DELIVERY_PAYMENT_TYPES_ERROR':
      return ({
        ...state,
        paymentMethods: initialState.paymentMethods,
      });

    case 'GET_DELIVERY_LOCATION_SUCCESS':
      return ({
        ...state,
        delivery: {
          ...state.delivery,
          newLatitude: action.payload.latitude,
          newLongitude: action.payload.longitude,
        },
      });

    default:
      return { ...state };
  }
};

export default delivey;
