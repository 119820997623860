import React from "react";
import TelemetryProvider from '../../utils/appInsightsProvider/telemetry-provider';
import OptimizerProvider from '../../utils/googleOptimizer';
import ClearCache from "../../components/ClearCache";

// REDUX 
import {Provider} from 'react-redux';
import Store from "./store";

// COMPONENTS
import Content from "./content";

class App extends React.Component {
  render() {
    return (
      <>
        <ClearCache />
        <TelemetryProvider instrumentationKey="2950710f-a96d-466d-bb5a-b5fabcb12b1b" history={this.history} />
        <OptimizerProvider />
        <Provider store={Store}>
          <Content />
        </Provider>
      </>
    );
  }
}

export default App;