const INITIAL_STATE = {
  addresses: [],
  flow: '',
  consultedAddress: {
    numero: '',
    logradouro: '',
    estado: '',
    cep: '',
    bairro: '',
    complemento: '',
  },
  feedback: null,
  isFetching: false,
  editManually: false,
  error: null,
};

const address = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'GET_ADDRESS_REQUEST':
      return {
        ...state,
        isFetching: true,
        addresses: [],
      };

    case 'GET_ADDRESS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        addresses: payload || [],
      };

    case 'GET_ADDRESS_FAILURE':
      return {
        ...state,
        isFetching: false,
        err: payload,
      };

    case 'INSERT_ADDRESS_REQUEST':
      return {
        ...state,
        isFetching: true,
      };

    case 'UPDATE_ADDRESS_REQUEST':
      return {
        ...state,
        isFetching: true,
      };

    case 'UPDATE_ADDRESS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        feedback: 'Endereço atualizado com sucesso!',
      };

    case 'UPDATE_ADDRESS_FAILURE':
      return {
        ...state,
        isFetching: false,
        err: payload,
        feedback: 'Erro ao atualizar endereço. Tente novamente ou contate-nos.',
      };

    case 'CREATE_ADDRESS_REQUEST':
      return {
        ...state,
        isFetching: true,
      };

    case 'CREATE_ADDRESS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        feedback: 'Endereço cadastrado com sucesso!',
      };

    case 'CREATE_ADDRESS_FAILURE':
      return {
        ...state,
        isFetching: false,
        err: payload,
        feedback: 'Erro ao criar endereço. Tente novamente ou contate-nos.',
      };

    case 'GET_ADDRESS_BY_CEP_REQUEST':
      return {
        ...state,
        isFetching: true,
        consultedAddress: {
          numero: '',
          logradouro: '',
          estado: '',
          cep: '',
          bairro: '',
          complemento: '',
        },
      };

    case 'GET_ADDRESS_BY_CEP_SUCCESS':
      return {
        ...state,
        isFetching: false,
        consultedAddress: payload,
      };

    case 'GET_ADDRESS_BY_CEP_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: payload.err,
        editManually: payload.editManually,
        consultedAddress: {},
      };

    case 'ADDRESS_CLEAR_FEEDBACK':
      return {
        ...state,
        feedback: null,
      };

    case 'ADDRESS_CLEAR_CONSULTED':
      return {
        ...state,
        consultedAddress: INITIAL_STATE.consultedAddress,
      };

    case 'ADDRESS_CLEAR':
      return {
        ...state,
        addresses: [],
        editManually: false,
      };

    default:
      return { ...state };
  }
};

export default address;
