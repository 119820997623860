const initialState = {
  loadingCapaign: true,
  campaignOffers: null,
  error: null,
  loadingDelivery: false,
  deliveryValues: [],
  redirectUrl: null,
  clientData: null,
};

const delivey = (state = initialState, action) => {
  switch (action.type) {
    case 'COVID_GET_PRODUCTS_LOADING':
      return {
        ...state,
        loadingCapaign: true,
        error: null,
      };
    case 'COVID_GET_PRODUCTS_SUCCESS':
      return {
        ...state,
        loadingCapaign: false,
        campaignOffers: action.payload,
      };
    case 'COVID_GET_PRODUCTS_ERROR':
      return {
        ...state,
        loadingCapaign: false,
        error: action.payload,
      };

    case 'COVID_GET_DELIVERY_VALUE_LOADING':
      return {
        ...state,
        loadingDelivery: true,
        error: null,
      }
    case 'COVID_GET_DELIVERY_VALUE_SUCCESS':
      return {
        ...state,
        loadingDelivery: false,
        deliveryValues: action.payload,
      }
    case 'COVID_GET_DELIVERY_VALUE_ERROR':
      return {
        ...state,
        loadingDelivery: false,
        error: action.payload,
      }

    case 'COVID_CREATE_ORDER_LOADING':
      return {
        ...state,
        loadingCapaign: true,
        error: null,
      };

    case 'COVID_CREATE_ORDER_SUCCESS':
      return {
        ...state,
        loadingCapaign: false,
        redirectUrl: action.payload.url,
        clientData: {
          celular: action.payload.celular,
          cep: action.payload.cep
        }
      };

    case 'COVID_CREATE_ORDER_ERROR':
      return {
        ...state,
        error: action.payload,
        loadingCapaign: false,
      };

    case 'COVID_CLEAR_ORDER_URL':
      return {
        ...state,
        redirectUrl: null,
      }

    default:
      return { ...state };
  }
};

export default delivey;
