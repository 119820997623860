const initialState = {
  // global
  error: null,
  users: [],
  // whatsapp
  sendWhatsLoading: false,
  // list
  getListLoading: false,
  getListInfinityLoading: false,
  list: [],
  listQuotation: [],
  listExpedition: [],
  listSearchOrder: [],
  listRenegotiation: [],
  listProduction: [],
  hasMore: true,
  hasMoreQuotation: true,
  hasMoreExpedition: true,
  hasMoreRenegotiation: true,
  hasMoreProduction: true,
  loadQuery: false,
  queryOpen: false,
  queryState: null,
  query: null,
  quotationQueryOpen: false,
  ExpeditionQueryOpen: false,
  SearchOrderQueryOpen: false,
  RenegotiarionQueryOpen: false,
  ProductionQueryOpen: false,
  queryStateQuotation: null,
  queryStateExpedition: null,
  queryStatSearchOrder: null,
  queryStatRenegitiation: null,
  queryStatProduction: null,
  queryQuotation: null,
  queryExpedition: null,
  querySearchOrder: null,
  queryRenegitiation: null,
  queryProduction: null,
  // details
  loadingCancelOrder: false,
  loadingRejectOrder: false,
  getOrderDetailsLoading: false,
  loadingUpdateBudget: false,
  loadingUpdateUserOrder: false,
  loadingUpdateBudgetError: false,
  excludeOrderLoading: false,
  orderPrintPrescription: null,
  order: {
    details: {
      id: null,
      createdAt: "",
      deliveryValue: 0,
      deliveryTime: 0,
      observation: "",
      paymentAvailable: ["cartao", "boleto", "entregador", "internet banking"],
      paymentSelected: null,
      discountCode: "",
      status: "",
      statusId: "",
      loginResponsavel: "",
      responsavelId: null,
      horaLinkVisualizado: null,
      linkVisualizado: false,
      nota: null,
      cobrancas: [],
      pedidoRepetido: false,
      NumOrcamento: null,
    },
    itens: [],
    client: {
      id: "",
      name: "",
      cpf: "",
      phone: "",
      whatsapp: "",
      email: "",
    },
    address: {
      id: "",
      title: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cep: "",
      cidade: "",
      estado: "",
    },
  },
  loadingSetResponsible: false,
  setResponsibleError: false,
  // tabs
  tabs: [],
  tabsQuotation: [],
  tabsExpedition: [],
  tabsRenegotiation: [],
  tabsProduction: [],
  statusList: [],
  clearTab: false,
  nextTab: false,
  newOrderLoading: false,
  newOrder: null,
  // comments
  comments: null,
  commentsLoading: false,
  postCommentLoading: false,
  refreshComments: false,
  // mass actions
  massActionItensCount: 0,
  pullMassActionsCount: 0,
  pullMassActions: false,
  presetMessageLoading: false,
  presetMessage: "",
  // Input channels
  inputChannels: [],
  // Tags
  TagList: [],

  // Cancel options
  cancelOptions: [],
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LIST_LOADING":
      return {
        ...state,
        getListInfinityLoading: true,
      };

    case "GET_LIST_SUCCESS":
      return {
        ...state,
        getListInfinityLoading: false,
        list: action.payload.list,
      };

    case "GET_LIST_ERROR":
      return {
        ...state,
        getListInfinityLoading: false,
        list: [],
      };

    case "GET_QUOTATION_LIST_SUCCESS":
      return {
        ...state,
        getListInfinityLoading: false,
        listQuotation: action.payload.list,
      };

    case "GET_QUOTATION_LIST_ERROR":
      return {
        ...state,
        getListInfinityLoading: false,
        listQuotation: [],
      };

    case "GET_EXPEDITION_LIST_SUCCESS":
      return {
        ...state,
        getListInfinityLoading: false,
        listExpedition: action.payload.list,
      };

    case "GET_EXPEDITION_LIST_ERROR":
      return {
        ...state,
        getListInfinityLoading: false,
        listExpedition: [],
      };

    case "GET_LIST_INFINITY_LOADING":
      return {
        ...state,
        getListInfinityLoading: true,
      };

    case "GET_LIST_INFINITY_SUCCESS":
      let new_list = state.list;
      let hasMore = true;

      if (action.payload.list.data.length <= 0) hasMore = false;

      new_list = new_list.concat(action.payload.list.data);

      return {
        ...state,
        getListInfinityLoading: false,
        list: new_list,
        hasMore: hasMore,
      };
    case "GET_LIST_INFINITY_ERROR":
      return {
        ...state,
        getListInfinityLoading: false,
        list: [],
      };

    case "GET_QUOTATION_LIST_INFINITY_SUCCESS":
      let new_list_Quo = state.listQuotation;
      let hasMore_Quo = true;

      if (action.payload.list.data.length <= 0) hasMore_Quo = false;

      new_list_Quo = new_list_Quo.concat(action.payload.list.data);

      return {
        ...state,
        getListInfinityLoading: false,
        listQuotation: new_list_Quo,
        hasMoreQuotation: hasMore_Quo,
      };
    case "GET_QUOTATION_LIST_INFINITY_ERROR":
      return {
        ...state,
        getListInfinityLoading: false,
        listQuotation: [],
      };

    case "GET_EXPEDITION_LIST_INFINITY_SUCCESS":
      let new_list_Exp = state.listExpedition;
      let hasMore_Exp = true;

      if (action.payload.list.data.length <= 0) hasMore_Exp = false;

      new_list_Exp = new_list_Exp.concat(action.payload.list.data);

      return {
        ...state,
        getListInfinityLoading: false,
        listExpedition: new_list_Exp,
        hasMoreExpedition: hasMore_Exp,
      };
    case "GET_EXPEDITION_LIST_INFINITY_ERROR":
      return {
        ...state,
        getListInfinityLoading: false,
        listExpedition: [],
      };

    case "GET_RENEGOTIATION_LIST_INFINITY_SUCCESS":
      let new_list_Reneg = state.listRenegotiation;
      let hasMore_Reneg = true;

      if (action.payload.list.data.length <= 0) hasMore_Reneg = false;

      new_list_Reneg = new_list_Reneg.concat(action.payload.list.data);

      return {
        ...state,
        getListInfinityLoading: false,
        listRenegotiation: new_list_Reneg,
        hasMoreRenegotiation: hasMore_Reneg,
      };
    case "GET_RENEGOTIATION_LIST_INFINITY_ERROR":
      return {
        ...state,
        getListInfinityLoading: false,
        listRenegotiation: [],
      };

    case "GET_PRODUCTION_LIST_INFINITY_SUCCESS":
      let new_list_Produc = state.listProduction;
      let hasMore_Produc = true;

      if (action.payload.list.data.length <= 0) hasMore_Produc = false;

      new_list_Produc = new_list_Produc.concat(action.payload.list.data);

      return {
        ...state,
        getListInfinityLoading: false,
        listProduction: new_list_Produc,
        hasMoreProduction: hasMore_Produc,
      };
    case "GET_PRODUCTION_LIST_INFINITY_ERROR":
      return {
        ...state,
        getListInfinityLoading: false,
        listProduction: [],
      };

    case "GET_LIST_INTERVAL_SUCCESS":
      return {
        ...state,
        list: action.payload.list.data,
      };

    case "GET_QUOTATION_LIST_INTERVAL_SUCCESS":
      return {
        ...state,
        listQuotation: action.payload.list.data,
      };

    case "GET_EXPEDITION_LIST_INTERVAL_SUCCESS":
      return {
        ...state,
        listExpedition: action.payload.list.data,
      };

    case "GET_SEARCHORDER_LIST_INTERVAL_SUCCESS":
      return {
        ...state,
        listSearchOrder: action.payload.list.data,
      };

    case "GET_RENEGOTIATIOM_LIST_INTERVAL_SUCCESS":
      return {
        ...state,
        listRenegotiation: action.payload.list.data,
      };

    case "GET_PRODUCTION_LIST_INTERVAL_SUCCESS":
      return {
        ...state,
        listProduction: action.payload.list.data,
      };

    case "GET_LIST_INTERVAL_ERROR":
      return {
        ...state,
      };

    case "GET_ORDER_LIST_QUERY_LOADING":
      return {
        ...state,
        hasMore: false,
        loadQuery: true,
      };

    case "GET_ORDER_LIST_QUERY_SUCCESS":
      return {
        ...state,
        list: action.payload.list.data,
        hasMore: false,
        loadQuery: false,
      };

    case "GET_ORDER_LIST_QUERY_ERROR":
      return {
        ...state,
        hasMore: false,
        loadQuery: false,
      };

    case "CLEAR_ORDER_LIST":
      return {
        ...state,
        list: [],
        hasMore: true,
      };

    case "GET_QUOTATION_LIST_QUERY_LOADING":
      return {
        ...state,
        hasMoreQuotation: false,
        loadQuery: true,
      };

    case "GET_QUOTATION_LIST_QUERY_SUCCESS":
      return {
        ...state,
        listQuotation: action.payload.list.data,
        hasMoreQuotation: false,
        loadQuery: false,
      };

    case "GET_QUOTATION_LIST_QUERY_ERROR":
      return {
        ...state,
        hasMoreQuotation: false,
        loadQuery: false,
      };

    case "CLEAR_QUOTATION_LIST":
      return {
        ...state,
        listQuotation: [],
        hasMoreQuotation: true,
      };

    case "GET_EXPEDITION_LIST_QUERY_LOADING":
      return {
        ...state,
        hasMoreExpedition: false,
        loadQuery: true,
      };

    case "GET_EXPEDITION_LIST_QUERY_SUCCESS":
      return {
        ...state,
        listExpedition: action.payload.list.data,
        hasMoreExpedition: false,
        loadQuery: false,
      };

    case "GET_EXPEDITION_LIST_QUERY_ERROR":
      return {
        ...state,
        hasMoreExpedition: false,
        loadQuery: false,
      };

    case "GET_RENEGOTIATION_LIST_QUERY_LOADING":
      return {
        ...state,
        hasMoreRenegotiation: false,
        loadQuery: true,
      };

    case "GET_PRODUCTION_LIST_QUERY_LOADING":
      return {
        ...state,
        hasMoreProduction: false,
        loadQuery: true,
      };

    case "GET_RENEGOTIATION_LIST_QUERY_SUCCESS":
      return {
        ...state,
        listRenegotiation: action.payload.list.data,
        hasMoreRenegotiation: false,
        loadQuery: false,
      };

    case "GET_PRODUCTION_LIST_QUERY_SUCCESS":
      return {
        ...state,
        listProduction: action.payload.list.data,
        hasMoreProduction: false,
        loadQuery: false,
      };

    case "GET_RENEGOTIATION_LIST_QUERY_ERROR":
      return {
        ...state,
        hasMoreRenegotiation: false,
        loadQuery: false,
      };

    case "GET_PRODUCTION_LIST_QUERY_ERROR":
      return {
        ...state,
        hasMoreProduction: false,
        loadQuery: false,
      };

    case "GET_SEARCHORDER_LIST_QUERY_LOADING":
      return {
        ...state,
        loadQuery: true,
      };
    case "GET_SEARCHORDER_LIST_QUERY_SUCCESS":
      return {
        ...state,
        listSearchOrder: action.payload.list.data,
        loadQuery: false,
      };
    case "GET_SEARCHORDER_LIST_QUERY_ERROR":
      return {
        ...state,
        loadQuery: false,
      };

    case "CLEAR_EXPEDITION_LIST":
      return {
        ...state,
        listExpedition: [],
        hasMoreExpedition: true,
      };

    case "CLEAR_SERARCHORDER_LIST":
      return {
        ...state,
        listSearchOrder: [],
      };

    case "CLEAR_RENEGOTIATION_LIST":
      return {
        ...state,
        listRenegotiation: [],
      };

    case "CLEAR_PRODUCTION_LIST":
      return {
        ...state,
        listProduction: [],
      };

    case "CLEAR_ORDER_DETAILS":
      return {
        ...state,
        order: initialState.order,
      };

    case "REMOVE_TAB_ORDER":
      let tabs_old = state.tabs;
      tabs_old.splice(action.payload, 1);

      if (tabs_old.length <= 0) {
        return {
          ...state,
          tabs: tabs_old,
          clearTab: true,
        };
      } else {
        return {
          ...state,
          tabs: tabs_old,
          nextTab: true,
        };
      }

    case "REMOVE_TAB_QUOTATION":
      let tabs_o = state.tabsQuotation;
      tabs_o.splice(action.payload, 1);

      if (tabs_o.length <= 0) {
        return {
          ...state,
          tabsQuotation: tabs_o,
          clearTab: true,
        };
      }

      return {
        ...state,
        tabsQuotation: tabs_o,
        nextTab: true,
      };

    case "REMOVE_TAB_EXPEDITION":
      let tabs_E = state.tabsExpedition;
      tabs_E.splice(action.payload, 1);

      if (tabs_E.length <= 0) {
        return {
          ...state,
          tabsExpedition: tabs_E,
          clearTab: true,
        };
      }

      return {
        ...state,
        tabsExpedition: tabs_E,
        nextTab: true,
      };

    case "REMOVE_TAB_RENEGOTIATION":
      let tabs_R = state.tabsRenegotiation;
      tabs_R.splice(action.payload, 1);

      if (tabs_R.length <= 0) {
        return {
          ...state,
          tabsRenegotiation: tabs_R,
          clearTab: true,
        };
      }

      return {
        ...state,
        tabsRenegotiation: tabs_R,
        nextTab: true,
      };

    case "REMOVE_TAB_PRODUTION":
      let tabs_Pro = state.tabsProduction;
      tabs_Pro.splice(action.payload, 1);

      if (tabs_Pro.length <= 0) {
        return {
          ...state,
          tabsProduction: tabs_Pro,
          clearTab: true,
        };
      }

      return {
        ...state,
        tabsProduction: tabs_Pro,
        nextTab: true,
      };

    case "CLEAR_CLEAR_TAB":
      return {
        ...state,
        clearTab: false,
      };

    case "CLEAR_NEXT_TAB":
      return {
        ...state,
        nextTab: false,
      };

    case "GET_ORDER_DETAILS_LOADING":
      return {
        ...state,
        getOrderDetailsLoading: action.payload,
      };

    case "GET_QUOTATIONS_DETAILS_SUCCESS":
      let tabsQuo = state.tabsQuotation;

      let addtabQuo = true;
      tabsQuo.map((val, index) => {
        if (val.id === action.payload.details.id) {
          addtabQuo = false;
        }
        return true;
      });

      if (addtabQuo) {
        tabsQuo.push({
          id: action.payload.details.id,
          code: `# ${action.payload.details.numPedido}`,
        });
      }

      return {
        ...state,
        getOrderDetailsLoading: false,
        order: {
          ...action.payload,
        },
        tabsQuotation: tabsQuo,
      };

    case "GET_EXPEDITION_DETAILS_SUCCESS":
      let tabsExp = state.tabsExpedition;
      let addtabExp = true;

      tabsExp.map((val) => {
        if (val.id === action.payload.details.id) {
          addtabExp = false;
        }
        return true;
      });

      if (addtabExp) {
        tabsExp.push({
          id: action.payload.details.id,
          code: `# ${action.payload.details.numPedido}`,
        });
      }

      return {
        ...state,
        getOrderDetailsLoading: false,
        order: {
          ...action.payload,
        },
        tabsExpedition: tabsExp,
      };

    case "GET_RENEGOTIATION_DETAILS_SUCCESS":
      let tabsReneg = state.tabsRenegotiation;
      let addtabReneg = true;

      tabsReneg.map((val, index) => {
        if (val.id === action.payload.details.id) {
          addtabReneg = false;
        }
        return true;
      });

      if (addtabReneg) {
        tabsReneg.push({
          id: action.payload.details.id,
          code: `# ${action.payload.details.numPedido}`,
        });
      }

      return {
        ...state,
        getOrderDetailsLoading: false,
        order: {
          ...action.payload,
        },
        tabsRenegotiation: tabsReneg,
      };

    case "GET_PRODUCAO_DETAILS_SUCCESS":
      let tabsProd = state.tabsProduction;
      let addtabProd = true;

      tabsProd.map((val) => {
        if (val.id === action.payload.details.id) {
          addtabProd = false;
        }
        return true;
      });

      if (addtabProd) {
        tabsProd.push({
          id: action.payload.details.id,
          code: `# ${action.payload.details.numPedido}`,
        });
      }

      return {
        ...state,
        getOrderDetailsLoading: false,
        order: {
          ...action.payload,
        },
        tabsProd: tabsProd,
      };

    case "GET_ORDER_PRESCRIPTION_SUCCESS":
      return {
        ...state,
        orderPrintPrescription: action.payload,
      };

    case "GET_ORDER_DETAILS_SUCCESS":
      let tabs = state.tabs;

      let addtab = true;
      tabs.map((val, index) => {
        if (val.id === action.payload.details.id) {
          addtab = false;
        }
        return true;
      });

      if (addtab) {
        tabs.push({
          id: action.payload.details.id,
          code: `# ${action.payload.details.numPedido}`,
        });
      }

      return {
        ...state,
        getOrderDetailsLoading: false,
        order: {
          ...action.payload,
        },
        tabs,
      };
    case "GET_ORDER_DETAILS_WITHOUT_TABS_SUCCESS":
      return {
        ...state,
        getOrderDetailsLoading: false,
        order: {
          ...action.payload,
        },
      };
    case "GET_ORDER_DETAILS_ERROR":
      return {
        ...state,
        getOrderDetailsLoading: false,
        error: "Ocorreu um erro.",
      };

    case "CLEAR_ERROR_ORDER":
      return {
        ...state,
        error: null,
      };

    case "REFRESH_ORDER_CLIENT_SUCCESS":
      return {
        ...state,
        order: {
          ...state.order,
          client: action.payload,
        },
      };

    case "GET_STATUS_LIST_ORDER":
      return {
        ...state,
        statusList: action.payload,
      };

    case "PUT_STATUS_SUCCESS":
      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            statusId: action.payload.idStatusPedido,
          },
        },
      };

    case "PUT_REJECT_STATUS_SUCCESS":
      return {
        ...state,
        loadingCancelOrder: false,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            statusId: action.payload.idStatusPedido,
            motivoCancelamentoMsg: action.payload.motive,
          },
        },
      };

    case "PUT_CANCEL_STATUS_LOADING":
      return {
        ...state,
        loadingCancelOrder: true,
      };
    case "PUT_CANCEL_STATUS_SUCCESS":
      return {
        ...state,
        loadingCancelOrder: false,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            statusId: action.payload.idStatusPedido,
          },
        },
      };
    case "PUT_CANCEL_STATUS_ERROR":
      return {
        ...state,
        loadingCancelOrder: false,
      };

    case "SET_BUDGET_USED":
      return {
        ...state,
        order: {
          ...state.order,
          itens: action.payload.itens,
          details: {
            ...state.order.details,
            orcamentoId: action.payload.id,
            porcentagemDesconto: action.payload.porcentagemDesconto,
            deliveryValue: action.payload.valorFrete,
            deliveryTime: action.payload.prazoEntrega,
            observation: action.payload.descricao,
          },
        },
      };

    case "SET_BUDGET_NEW_ORDER":
      return {
        ...state,
        order: {
          ...state.order,
          itens: [],
          details: {
            ...state.order.details,
            orcamentoId: null,
            deliveryTime: 0,
            deliveryValue: 0,
            porcentagemDesconto: 0,
            observation: null,
          },
        },
      };

    case "SEND_MESSAGE_WHATSAPP_REQUEST":
      return {
        ...state,
        sendWhatsLoading: true,
      };

    case "SEND_MESSAGE_WHATSAPP_SUCCESS":
      return {
        ...state,
        sendWhatsLoading: false,
      };

    case "SEND_MESSAGE_WHATSAPP_FAILURE":
      return {
        ...state,
        sendWhatsLoading: false,
      };

    case "UPDATE_ORDER_ITENS":
      return {
        ...state,
        order: {
          ...state.order,
          itens: action.payload,
        },
      };

    case "UPDATE_ORDER_DETAILS":
      return {
        ...state,
        loadingUpdateBudget: false,
        loadingUpdateBudgetError: false,
      };

    case "UPDATE_ORDER_DETAILS_LOADING":
      return {
        ...state,
        loadingUpdateBudget: true,
      };

    case "UPDATE_ORDER_DETAILS_ERROR":
      return {
        ...state,
        loadingUpdateBudget: false,
        loadingUpdateBudgetError: true,
      };

    case "SET_ADDRESS_ORDER":
      return {
        ...state,
        order: {
          ...state.order,
          address: {
            ...action.payload,
          },
        },
      };

    case "POST_NEW_ORDER":
      return {
        ...state,
        newOrderLoading: true,
      };
    case "POST_NEW_ORDER_SUCCESS":
      return {
        ...state,
        newOrderLoading: false,
        newOrder: action.payload,
      };
    case "POST_NEW_ORDER_FAIL":
      return {
        ...state,
        newOrderLoading: false,
        newOrderErr: action.payload,
      };
    case "CLEAR_NEW_ORDER":
      return {
        ...state,
        newOrder: null,
      };

    // coments
    case "ORDER_COMMENT_LOADING":
      return {
        ...state,
        commentsLoading: true,
      };
    case "ORDER_COMMENTS_SUCCESS":
      return {
        ...state,
        comments: action.payload,
        commentsLoading: false,
        refreshComments: false,
      };
    case "ORDER_COMMENTS_ERROR":
      return {
        ...state,
        comments: action.payload,
        commentsLoading: false,
        refreshComments: false,
      };
    case "ORDER_CLEAR_COMMENT":
      return {
        ...state,
        comments: null,
      };
    case "ORDER_POST_COMMENT_LOADING":
      return {
        ...state,
        postCommentLoading: true,
      };
    case "ORDER_POST_COMMENT_SUCCESS":
      return {
        ...state,
        postCommentLoading: false,
        refreshComments: true,
      };
    case "ORDER_POST_COMMENT_ERROR":
      return {
        ...state,
        postCommentLoading: false,
      };

    case "ORDER_UPDATE_LOADING":
      return {
        ...state,
        loadingUpdateBudget: true,
      };

    case "ORDER_UPDATE_SUCCESS":
      return {
        ...state,
        loadingUpdateBudget: false,
      };

    case "ORDER_UPDATE_ERROR":
      return {
        ...state,
        loadingUpdateBudget: false,
      };

    case "REMOVE_ORDER_CLIENT_SUCCESS":
      return {
        ...state,
        newOrder: action.payload,
      };

    case "ORDER_POST_USER_LOADING":
      return {
        ...state,
        loadingUpdateUserOrder: true,
      };

    case "ORDER_POST_USER_SUCCESS":
      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            loginResponsavel: action.payload.user,
            responsavelId: action.payload.userId,
          },
        },
        loadingUpdateUserOrder: false,
      };

    case "ORDER_SET_QUERY_STATUS":
      return {
        ...state,
        queryOpen: action.payload.status,
        hasMore: !action.payload.status,
        queryState: action.payload.queryState,
        query: action.payload.query,
      };

    case "QUOTATION_SET_QUERY_STATUS":
      return {
        ...state,
        quotationQueryOpen: action.payload.status,
        hasMoreQuotation: !action.payload.status,
        queryStateQuotation: action.payload.queryState,
        queryQuotation: action.payload.query,
      };

    case "EXPEDITION_SET_QUERY_STATUS":
      return {
        ...state,
        ExpeditionQueryOpen: action.payload.status,
        hasMoreExpedition: !action.payload.status,
        queryStateExpedition: action.payload.queryState,
        queryExpedition: action.payload.query,
      };

    case "RENEGOTIATION_SET_QUERY_STATUS":
      return {
        ...state,
        RenegitiationQueryOpen: action.payload.status,
        hasMoreRenegotiation: !action.payload.status,
        queryStatRenegitiation: action.payload.queryState,
        queryRenegitiation: action.payload.query,
      };

    case "PRODUCTION_SET_QUERY_STATUS":
      return {
        ...state,
        ProductionQueryOpen: action.payload.status,
        hasMoreProduction: !action.payload.status,
        queryStatProduction: action.payload.queryState,
        queryProduction: action.payload.query,
      };

    case "GET_ORDER_USER_LIST":
      return { ...state, users: action.payload.list };

    case "ORDER_CLEAR_USER_LIST":
      return { ...state, users: [] };

    case "GET_ORDER_FILTER_LIST_ERROR":
      return { ...state, listError: !state.listError };

    // Mass Actions

    case "QUOTATION_ADD_MASS_ACTION_ITEM":
      return {
        ...state,
        massActionItensCount: state.massActionItensCount + 1,
        pullMassActionsCount: state.massActionItensCount + 1,
      };

    case "QUOTATION_REMOVE_MASS_ACTION_ITEM":
      return {
        ...state,
        massActionItensCount: state.massActionItensCount - 1,
        pullMassActionsCount: state.massActionItensCount - 1,
      };

    case "QUOTATION_MAKE_MASS_ACTION":
      return {
        ...state,
        pullMassActions: true,
      };

    case "QUOTATION_CLEAR_MASS_ACTION":
      if (state.pullMassActionsCount - 1 === 0) {
        return {
          ...state,
          pullMassActions: false,
          pullMassActionsCount: 0,
          massActionItensCount: 0,
        };
      }

      return {
        ...state,
        pullMassActionsCount: state.pullMassActionsCount - 1,
      };

    case "QUOTATION_CLEAR_ALL_MASS_ACTION":
      return {
        ...state,
        massActionItensCount: 0,
        pullMassActionsCount: 0,
        pullMassActions: false,
      };

    case "ORDER_POP_EXCLUDE_ITEM_LOADING":
      return {
        ...state,
        excludeOrderLoading: true,
      };

    case "ORDER_POP_EXCLUDE_ITEM_SUCCESS":
      return {
        ...state,
        excludeOrderLoading: false,
      };

    case "ORDER_POP_EXCLUDE_ITEM_ERROR":
      return {
        ...state,
        excludeOrderLoading: false,
        error: "Erro ao excluir!",
      };

    case "ORDER_GET_MESSAGE_ORDER_LOADING":
      return {
        ...state,
        presetMessageLoading: true,
      };

    case "ORDER_GET_MESSAGE_ORDER_SUCCESS":
      return {
        ...state,
        presetMessageLoading: false,
        presetMessage: action.payload,
      };

    case "ORDER_GET_MESSAGE_ORDER_ERROR":
      return {
        ...state,
        presetMessageLoading: false,
      };

    case "ORDER_CLEAR_MESSAGE_ORDER":
      return {
        ...state,
        presetMessage: "",
      };

    case "PUT_PAYMENT_STATUS_SUCCESS":
      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            statusCobrancaId: action.payload,
          },
        },
      };

    case "ORDER_SET_FREE_DELIVERY":
      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            freteGratis: action.payload,
          },
        },
      };

    case "ORDER_SET_AUTOMATION_CHANNEL":
      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            canalComunicacao: action.payload.channelName,
            canalComunicacaoId: action.payload.channelId,
          },
        },
      };

    case "ORDER_INPUT_CHANNEL":
      return {
        ...state,
        inputChannels: action.payload,
      };

    case "ORDER_PUT_PAYMENT_RECEIPT_SUCCESS": {
      if (state.order.details.cobrancas.length === 0) {
        return { ...state };
      }

      let newPayment = state.order.details.cobrancas;
      newPayment[0] = { ...newPayment[0], comprovanteUrl: action.payload };

      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            cobrancas: newPayment,
          },
        },
      };
    }

    case "ORDER_GET_ALL_TAGS_SUCCESS":
      return {
        ...state,
        tagList: action.payload,
      };

    case "UPDATE_ORDER_TAGS_LOADING":
      return {
        ...state,
        updateTagLoading: true,
      };

    case "UPDATE_ORDER_TAGS_SUCCESS": {
      const tagList = state.order.details.tags;

      tagList.push(action.payload);

      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            tags: tagList,
          },
        },
        updateTagLoading: false,
      };
    }
    case "UPDATE_ORDER_TAGS_ERROR":
      return {
        ...state,
        updateTagLoading: false,
      };

    case "DELETE_ORDER_TAGS_SUCCESS": {
      const tagList = [];

      state.order.details.tags.map((item) => {
        if (item.id !== action.payload) tagList.push(item);

        return true;
      });

      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            tags: tagList,
          },
        },
        updateTagLoading: false,
      };
    }

    case "ORDER_SET_RESPONSIBLE_LOADING":
      return {
        ...state,
        loadingSetResponsible: true,
        setResponsibleError: false,
      };

    case "ORDER_SET_RESPONSIBLE_SUCCESS":
      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            loginResponsavel: action.payload,
          },
        },
        loadingSetResponsible: false,
      };

    case "ORDER_SET_RESPONSIBLE_ERROR":
      return {
        ...state,
        loadingSetResponsible: false,
        setResponsibleError: true,
      };

    case "ORDER_POST_TRACKING_CODE_SUCCESS":
      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            entrega: {
              ...action.payload,
            },
          },
        },
      };

    case "SET_FORMULA_CERTA_NUMBER": {
      return {
        ...state,
        order: {
          ...state.order,
          details: {
            ...state.order.details,
            NumOrcamento: action.payload,
          },
        },
      };
    }

    case "SET_CANCEL_OPTIONS": {
      return {
        ...state,
        cancelOptions: action.payload,
      };
    }

    case "GET_PRODUCTION_ORDER": {
      return {
        ...state,
      };
    }

    case "CHECKOUT_REJECT_ORDER_LOADING": {
      return {
        ...state,
        loadingRejectOrder: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

export default order;
