import React from 'react';
import { Modal, CircularProgress, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
//import { maniPharmacyList } from './maniPharmacysList';
import style from './style.css';

export default class MessageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewReliaseModal: false,
      canCloseModal: false,
    };
  }

  componentWillMount() {
    //const data = localStorage.getItem('viewMessageModal');

    //if (!data && this.checkisManiPharmacy()) this.setState({ showNewReliaseModal: true });

    setTimeout(() => this.setState({ canCloseModal: true }), 10000);
  }

  // checkisManiPharmacy() {
  //   const atualId = localStorage.getItem('farmaciaId');   
    
  //   if (maniPharmacyList.indexOf(atualId.toUpperCase()) !== -1) {
  //     return true;
  //   }

  //   return false;
  // }

  closeModal() {
    this.setState({ showNewReliaseModal: false });
    localStorage.setItem("viewMessageModal", true);
  }

  render() {
    const { showNewReliaseModal, canCloseModal } = this.state;

    return (
      <Modal
        open={showNewReliaseModal}
      >
        <div className={style.modalContainer}>

          {canCloseModal ? (
            <IconButton
              className={style.closeBtn}
              style={{ position: 'absolute' }}
              onClick={() => this.closeModal()}
            >
              <Close fontSize="small" />
            </IconButton>
          ) : (
            <CircularProgress className={style.closeBtn} size={22} />
          )}

          <h1 className={style.modalTitle}>
            {`ATENÇÃO A PARTIR DO DIA 16/03 `}
          </h1>
          <h1 className={style.modalTitle}>
            {`PRÓXIMA SEGUNDA-FEIRA, OS VALORES DE FRETE SERÃO CALCULADOS AUTOMATICAMENTE PELO SISTEMA`}
          </h1>

          <h2 style={{ textAlign: 'left', marginBottom: '18px' }}>
            {`Como garantir que meu sistema está configurado corretamente?`}
          </h2>

          <ul className={style.listStyle}>
            <li>
              {`acessar o menu do sistema, em configurações, logística, e cadastrar 
              os valores de frete por faixas de CEP, para todas localidades onde a 
              farmácia realiza entrega com motoboy`}
            </li>
            <li>
              {`os demais endereços que não constarem na tabela de frete via motoboy, 
              o sistema vai automaticamente calcular e cobrar o cliente pelo valor de 
              entrega via PAC 1kg dos Correios`}
            </li>
            <li>
              {`é possível também configurar uma regra para que o sistema aplique e 
              ofereça ao seu cliente frete grátis, para pedidos acima de um valor R$ X`}
            </li>
            <li>
              {`Todos os valores de frete e regras de entrega deverão ser lançados na 
              configuração logística do seu sistema, e não será mais permitido o 
              lançamento manual de valor de frete nos pedidos.`}
            </li>
          </ul>

          <p><b>
            {`Se tiver alguma dúvida, por favor entre em contato com João Rodrigues, 
            no e-mail `}
            <b style={{ color: '#006ED0' }}>joao.rodrigues@manipulae.com.br</b>
            {` , e envie sua tablea de valores 
            de frete via e-mail caso queira solicitar suporte para realizar esta 
            configuração.`}</b>
          </p>

        </div>
      </Modal>
    );
  };
}
